import { doc, onSnapshot, collection, getDoc } from "firebase/firestore"; //
import { db } from "../../config/firebase"; //connect to firestore db
import { actionTypes } from "../../statemanagement/reducer";

const subscriptionsCollection = collection(db, "stripe");


//**FIX BUG STORE SESSION INFO IN BROWSER
export const listenToUpdatesInSubscriptionsCollection = (userId, dispatch) => {
  const subscriptionRef = doc(subscriptionsCollection, userId);

  const unsubscribe = onSnapshot(subscriptionRef, (subscriptionDoc) => {
    console.log("Subscriptions Collection has been modified");
    if (subscriptionDoc.exists()) {
      console.log("Subscription for the user exists in DB");
      const subscriptionData = subscriptionDoc.data();
      console.log("Setting Subscription data from DB to local state");
      dispatch({type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscription: subscriptionData});
    //  dispatch({type: actionTypes.SET_LOGIN_LOADING, loginLoading: false});
    } else {
      console.log("User document doesn't exist");
      dispatch({type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscription: null});
    }
  });

  return unsubscribe; // Return a function to stop listening for changes when needed
};

export const fetchActiveSubscriptions = async (userId) => {
  const fetchSubscriptionData = async () => {
    const subscriptionRef = doc(subscriptionsCollection, userId);
    const subscriptionDoc = await getDoc(subscriptionRef);
    if(subscriptionDoc.exists()){
      return subscriptionDoc.data();
    //  dispatch({type: actionTypes.SET_PAYPAL_SUBSCRIPTION, subscription: subscriptionData});
    }
  }

  fetchSubscriptionData();
  
}
