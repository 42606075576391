import { actionTypes } from "../statemanagement/reducer"; //fire action type to update state with logged in/out user
import { useEffect } from "react"; //on Mount listen for onauth changed events
import { useStateValue } from "../statemanagement/StateProvider";
 import { cachedBingNewsHeadlines, cachedNewsAggregatorHeadlines } from "../strings/news";
import axios from "axios";

const useHeadlines = () => {

    const [{news}, dispatch] = useStateValue();
    //const navigate = useNavigate();

    useEffect(()=>{
        
        const fetchHeadlines = async () => {
        //    const response = await axios.get(`http://localhost:8080/newsapi`);
        //    const headlines = response.data.news.map(u=>
            
        //     {
        //         if(!!u.urlToImage){
        //             return  {
        //                 "name": u.title,
        //                 "url": u.url,
        //                 "image": {
        //                     "contentUrl": u.urlToImage,
        //                 },
        //                 "description": u.description,
        //             }
        //         } else{
        //             return  {
        //                 "name": u.title,
        //                 "url": u.url,
        //                 "description": u.description,
        //             }
        //         }
            
        //    })

            // const response = await axios.get(`http://localhost:8080/news`);
            // const headlines = response.data.news;
            
            // const cachedHeadlines = cachedBingNewsHeadlines.concat(cachedNewsAggregatorHeadlines.articles.map(u=>({
            //            "name": u.title,
            //             "url": u.url,
            //             "image": {
            //                 "contentUrl": u.urlToImage,
            //             },
            //             "description": u.description,
            // })))

            const cachedHeadlines = cachedNewsAggregatorHeadlines.articles.map(u=>({
                "name": u.title,
                 "url": u.url,
                 "image": {
                     "contentUrl": u.urlToImage,
                 },
                 "description": u.description,
            }));

            dispatch({type: actionTypes.SET_NEWS, news: shuffleArray(cachedHeadlines)})
        }

        console.log("In News Headlines hook")

        if(news.length == 0){
            fetchHeadlines();
        }
        
    },[])

    return null;
}

function shuffleArray(array) {
    return array
    .map((value) => ({ value, sortKey: Math.random() }))
    .sort((a, b) => a.sortKey - b.sortKey)
    .map(({ value }) => value);
    }

export default useHeadlines;
