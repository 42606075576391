import "./Search.css";
import { Button } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import {promptExamples} from "../mock/PromptAssistanceExamples";
import CreateIcon from '@mui/icons-material/Create';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {actionTypes} from "../statemanagement/reducer";
import {clientCache} from "../mock/ClientCache";
import { useNavigate } from "react-router-dom";
import SubIconRow from "../pages/SubIconRow";
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography";
import { MedicalInformation } from "@mui/icons-material";
import PlaceIcon from '@mui/icons-material/Place';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SchoolIcon from '@mui/icons-material/School';
import MultiActionAreaCard from "./MultiActionAreaCard";
import MediaControlCard from "./MediaControlCard";
import MultiActionVideoCard from "./MultiActionVideoCard";
import MultiActionVisualCardLanguage from "./MultiActionVisualCardLanguage";

//https://api.bing.microsoft.com/v7.0/news/search

const LandingPrompts = ({ dispatch, streaming, postFunc, user, appMode, news }) => {

    const navigate = useNavigate();

    const handleClick = (term, cache = false, edit=false) => {
        console.log(`Term is: ${term}`)
      //  console.log(`Streaming: ${streaming}`);
        if (streaming) return;

         postFunc(edit);

        if(cache){
            setTimeout(()=>{
                dispatch({
                    type: actionTypes.UPDATE_CONVERSATION_HISTORY,
                    enrichedConversationHistory: clientCache(term) 
                  });
            }, 50);
        }else {
            dispatch({ type: 'SET_SEARCH_TERM', term });
        }
        
        window.gtag('event', 'Prompt_Assistance', {
            event_category: 'Prompt_Assistance',
            event_label: term, 
        });

    };

    const promptAssistanceByMode = () => {
        // const getUrlParam = (param) => {
        //   const searchParams = new URLSearchParams(window.location.search);
        //   return searchParams.get(param);
        // };
      
        // const mode = getUrlParam("mode") || "generic";
      
        switch (appMode) {
          case "generic":
            return (
              <>
               {/* <Button onClick={() => handleClick("UPI", true)}>
                  What is UPI Lite? 
                </Button> */}
                
                <Button onClick={() => handleClick("Pollution level in Delhi today")}>
                 Pollution levels in Delhi today
                </Button>

                <Button onClick={() => handleClick("Price of Petrol in Mumbai today")}>
                  Price of Petrol in Mumbai today
                </Button>
                
                <Button onClick={() => handleClick("derivative", true)}>
                What is d/dx*(x^5)?
                </Button>
                <Button onClick={() => handleClick("bharat", true)}>
                  भारत और इंडिया के बीच का अंतर
                </Button>
                
                
                
              </>
            );
          case "location":
            return (
              <>
                <Button onClick={() => handleClick("Recommend 3 good gyms in Gurgaon")}>
                Recommend 3 good gyms in Gurgaon
                </Button>
                <Button onClick={() => handleClick("Recommend 3 good dentists in Gurgaon")}>
                Recommend 3 good dentists in Gurgaon
                </Button>
                <Button onClick={() => handleClick("What events are happening in Gurgaon this weekend?")}>
                  What events are happening in Gurgaon this weekend?
                </Button>
              </>
            );
          case "medical":
            return (
                
                
                    <>
                        <Button onClick={() => handleClick("Ayurvedic Home Remedies for Acidity/Acid burn")}>
                        Ayurvedic Home Remedies for Acidity/Acid burn
                        </Button>
                        <Button onClick={() => handleClick("What is Ketogenic Diet?")}>
                        What is Ketogenic Diet?
                        </Button>
                        <Button onClick={() => handleClick("Common cold Home Remedies")}>
                        Ayurvedic, Common cold Home Remedies
                        </Button>
                    </>
              
            );
            case "sports":
                return (
                  <>
                    <Button onClick={() => handleClick("India vs Australia, Shoaib Akhtar Predictions")}>
                      India vs Australia, Shoaib Akhtar Predictions
                    </Button>
                    <Button onClick={() => handleClick("Will Ashwin play in the finals?")}>
                       Will Ashwin play in the finals?
                    </Button>
                    <Button onClick={() => handleClick("Slow pitch in Ahmedabad")}>
                       Slow pitch in Ahmedabad
                    </Button>
                  </>
                );
            case "entertainment":
                    return (
                      <>
                        <Button onClick={() => handleClick("Top SRK News")}>
                          Top SRK News
                        </Button>
                        <Button onClick={() => handleClick("Which Celebs are going to be present in Ahmedabad for World Cup Finals?")}>
                           Which Celebs are going to be present in Ahmedabad?
                        </Button>
                        {/* <Button onClick={() => handleClick("Common cold Home Remedies")}>
                           Ayurvedic, Common cold Home Remedies
                        </Button> */}
                      </>
                    );
                case "finance":
                        return (
                          <>
                            <Button onClick={() => handleClick("Stock Price of TCS today")}>
                              Stock Price of TCS today
                            </Button>
                            <Button onClick={() => handleClick("Apply Warren Buffet Investment Strategy to Indian stock market")}>
                               Apply Warren Buffet Investment Strategy to Indian stock market
                            </Button>
                            <Button onClick={() => handleClick("Top Stock Picks for 2024")}>
                               Top Stock Picks for 2024
                            </Button>
                          </>
                        );
          default:
            return null; // Handle unknown mode or provide a default behavior
        }
      };
      
      
console.log(news);
const newsElements = news.filter(u=>!!u.image).map(u=>{

    return <> <MediaControlCard name={u.name} image={u.image} prompt={u.prompt} onClick={(a)=>handleClick(a, true)}/> <br/></>
})
const elements = news.filter(u=>!!u.image).map(u=>{
    console.log(clientCache(u.name));
    const imageUrl = !!u.image ? u.image.contentUrl : "";
    if(clientCache(u.name).length >=1){
        return (<><MultiActionAreaCard navigate={navigate} name={u.name} image={u.image.contentUrl} description={u.description} url={u.url} onClick={()=>handleClick(u.name, true)} /><br/></>);
    } else {
        
            return (<><MultiActionAreaCard navigate={navigate} name={u.name} image={u.image.contentUrl} description={u.description} url={u.url} onClick={()=>handleClick(u.name)} /><br/></>)
        
    }
    // return (clientCache(u.name).length >=1) ? (<><MultiActionAreaCard name={u.name} image={u.image.contentUrl} description={u.description} url={u.url} onClick={()=>handleClick(u.name, true)} /><br/></>):
    // (<><MultiActionAreaCard name={u.name} image={u.image.contentUrl} description={u.description} url={u.url} onClick={()=>handleClick(u.name)} /><br/></>)
})
// const elems = Array.from({length: 30}, (item, index) => {
//     return <><MultiActionAreaCard /><br/></>
// })


    return (
         
        <div className='prompt_assistance_button_group_countainer'>
           <div style={{margin:"2%", fontSize:"smaller"}}><i>*This app is powered by AI (Artificial Intelligence). Please review the generated text carefully. </i></div> <br/>
            {[ <MultiActionVideoCard />, <MultiActionVisualCardLanguage/>].concat(elements)}
            {/* {elements} */}
            <br/>
            <br/>
            {/* <div className="prompt_assistance_button_group_countainer_header">
               <DoubleArrowIcon style={{opacity:0.65}}/>
                <div style={{paddingLeft:"0.6%", fontSize:"1em"}}>Try the below examples</div> 
            </div> */}
            {/* <div className='prompt_assistance_button_group' style={{paddingTop:"0.3%", marginBottom:"10%"}}> */}
            
            {/* <Button  onClick={() => handleClick("g20", true)}>
                     G20 News - AI Summary
                    </Button> */}
                   
                    
                    
                    {/* <Button
                            onClick={() => {
                                handleClick("yoga", true);
                            }}>
                    {"Plan a Yoga session for me"}
                    </Button> */}
                    {/* <Button onClick={() => handleClick("Write Python code to detect odd or even, output format should be in code")}>
                        {"Write Python code to detect odd or even"}
                    </Button> */}
                    {/* {(appMode == "medical") &&  <SubIconRow/> }
                    <br /> */}
                    {/* {promptAssistanceByMode()} */}
                    {/* <Button onClick={() => handleClick("marketing", true)}>
                        {"Marketing strategies for Gen Z vs Millennials"}
                    </Button>  */}
                    

                    {/* {!user && <p style={{textAlign:"left", paddingTop:"5%", display:"flex"}}><Button style={{background:"black", opacity:"0.8", borderRadius:"18px", textTransform:"inherit", minWidth:"25%"}} variant="contained" onClick={_=> navigate("/login")}>Sign In</Button> <p style={{paddingLeft:"2%", fontSize:"12px", fontWeight:"500", marginTop:"auto", marginBottom:"auto"}}>to enable GPT-4, world's most powerful AI</p></p> } */}
                    {/* <Button onClick={() => handleClick("How Microsoft makes money?")}>
                        {"How Microsoft makes money?"}
                    </Button>  */}
                    {/* <Button onClick={() => handleClick("Fastest way to register a company in the US online in 2023?")}>
                        {"Types of companies th?"}
                    </Button> */}
                    
                    {/* <Button onClick={() => handleClick("What's the current exchange rate between the US dollar and the euro?")}>
                        {"What's the current exchange rate between the US dollar and the euro?"}
                    </Button>  */}
                    {/* <Button 
                            onClick={() => {
                                handleClick("derivative", true);
                            }}>
                        {"d/dx (x^5)"}
                    </Button> */}
                     {/* <Button onClick={() => handleClick("News Headlines, Times of India")}>
                        {"News Headlines"}
                    </Button> */}
                    
                    
                    {/* <Button onClick={() => handleClick("resume-fe", true)}>
                        {"Create a Resume for a front-end Engineer"}
                    </Button>  */}
                    {/* <Button onClick={() => handleClick("games", true)}>
                        {"Games announced for Xbox Game Pass in October 2023"}
                    </Button>  */}

                    
                    
                    
                     
                                      
                    
                    {/* <Button onClick={() => handleClick("goa", true)}>
                        {"Plan 3 day trip to Goa, India"}
                    </Button>  */}
                    {/* <Button onClick={() => handleClick("resume-ch", true)}>
                        {"Create a Resume for a Chef"}
                    </Button>  */}
                    
                    {/* <Button  onClick={() => handleClick("productivity", true)}>
                        {"How can AI Boost the Productivity of an Organization?"}
                    </Button> */}
                     
                    {/* <Button  onClick={() => handleClick("us", true)}>
                    AI News e.g. US Open 2023 Result 
                    </Button> */}
                    {/* <Button onClick={() => handleClick("review", true)}>
                        {"AI Review: Xiaomi Redmi 12"}
                    </Button>
                     */}
                    
                    
                   
                    {/* <Button  onClick={() => handleClick("Stock price of Amazon")}>
                        Live data e.g. Amazon stock price today
                    </Button> */}
                    
            {/* <Button  onClick={() => handleClick("republican", true)}>
                        Republican Debates - AI highlights
                    </Button> */}
                   
                    
            {/* <Button  onClick={() => handleClick("Amazon Stock price today")}>
                        Amazon Stock price today
                    </Button> */}
                       
                
                    {/* <Button
                            onClick={() => {
                                handleClick("Write a short Happy birthday greeting for my friend add emojis", true);
                            }}>
                    {"Write a greeting e.g. Happy Birthday"}
                    </Button> */}
                   
                    {/* <Button 
                            onClick={() => {
                                handleClick("Square root of 20", true);
                            }}>
                        {"Square root of 20"}
                    </Button> */}
                    {/* <Button onClick={() => {
                            dispatch({
                                type: actionTypes.SET_CHAT_MODE,
                                chatMode: true 
                              });
                            handleClick("Chat")
                        }}>
                            {"Chat about ChatDragon.ai"}
                        </Button>  */}
                   
                    
                    
                    {/* <Button  onClick={() => handleClick("world", true)}>
                        World News AI Summary
                    </Button>
                    */}
                    
                   
                   
                    {/* <Button 
                            onClick={() => {
                                handleClick("Write a Cold email to a hiring manager");
                            }}>
                        {"Cold email to a hiring manager"}
                    </Button> */}
                    {/* <Button 
                            onClick={() => {
                                handleClick("How do I take the perfect selfie?");
                            }}>
                        {"How do I take the perfect selfie?"}
                    </Button> */}
                    {/* <Button  onClick={() => handleClick("Smartphones under ₹10,000", true)}>
                        {"Smartphones under ₹10,000"}
                    </Button> */}
                   
                    
                   
                    {/* <Button onClick={() => handleClick("solar", true)}>
                    <img src="https://r1.ilikewallpaper.net/ipad-wallpapers/download/6499/Our-Solar-System-ipad-wallpaper-ilikewallpaper_com.jpg" style={{height:"150px", width:"170px"}}></img>
                        <div>Solar System Images</div>
                    </Button> */}
                    {/* <br /><br /> */}
            {/* <Button style={{background:"#100c08", fontSize:"0.65em", color:"white",border:"none", fontWeight:"700", marginBottom:"10px"}} onClick={() => handleClick("Latest Update on Pragyaan Rover", true)}>
                    <img src="https://wp-assets.futurism.com/2023/08/india-rover-moon-south-pole.jpg" style={{height: "150px"}}/>
                        <div>Chandrayaan 3, Pragyaan Rover encounters Crater</div>
                    </Button> */}
            
                    
                    
                    {/* <Button onClick={() => handleClick("d/dx (x^3)")} >
                        {"d/dx (x^3)"}
                    </Button> */}
                   
                   {/* <Button style={{background:"#343434", color:"white"}} onClick={() => handleClick("lineup", true)}>
                    <img src="https://images.indianexpress.com/2017/06/india-vs-pakistan-m3.jpg" style={{height: "150px"}} />
                         <div>Asia Cup 2023, India vs Pakistan lineup</div>
                    </Button> */}

                   
                   
                    
                    {/* <Button onClick={() => handleClick("Asia Cup 2023, Give me tournament schedule in a table")}>
                         Asia Cup 2023, Tournament schedule in a table 
                    </Button>
                    
                    
                    <Button  
                            onClick={() => {
                                handleClick("List rental options for 1 BHK flat in Gurgaon");
                            }}>
                        {"List rental options for 1 BHK flat in Gurgaon"}
                    </Button>
                    */}
                    
                  

                    {/** Promotion */}
                    {/* <Button onClick={() => handleClick("increment", true)} >
                        {"Write a letter asking for a promotion"}
                    </Button> */}
                    
                    
                    
                    
                    {/* <Button style={{background:"#EFEAE9", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("Asia Cup 2023 matches today")}>
                         Asia Cup today
                    </Button> */}
            
            
                   
                    
{/*                     
                    <Button 
                            onClick={() => {
                                handleClick("india news", true);
                            }}>
                        {"India News Summary"}
                    </Button> */}
                    
                    
                    
                    
                    
                    {/* <Button style={{background:"#EFEAE9", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("tomato", true)}>
                        <div>टमाटर का भाव</div>
                    </Button> */}
                    
                   
                    
                   {/* <Button onClick={() => handleClick("increment", true)} style={{background:"#EFEAE9", fontSize:"0.6em", color:"#13343b",border:"none", fontWeight:"700"}} >
                        {"Write a letter asking manager for a salary increment"}
                    </Button> */}
                    

                    
              
                    
                    
                                            
                    {/* <Button style={{background:"#EFEAE9", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("Top dentists in Gurugram", true)}>
                        <div>Top Dentists in Gurugram</div>
                    </Button> */}

                    {/* <Button style={{background:"#EFEAE9", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("Top gyms in Mumbai less than ₹3500 per month", true)}>
                        <div>Top Gyms in Mumbai less than ₹3500 per month</div>
                    </Button> */}
                   
                
                   
                    
                   
                     
                    {/* <Button style={{background:"#EFEAE9", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("Write sick leave email for me", true)}>
                        {"Write a sick leave email"}
                    </Button>  */}

                    
                     
                
                    {/* <Button style={{background:"#EFEAE9", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("Gadar 2 box office", true)}>
                        <div>Gadar 2 box office</div>
                    </Button> */}
                    {/* <Button style={{background:"#EFEAE9", textTransform:"none", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700", marginBottom:"2%", marginTop:"2%"}} onClick={() => handleClick("bharatchat", true)}>
                            {"What is BharatChat.ai?"}
                    </Button> */}
                    {/* <Button style={{background:"#EFEAE9", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("Write a happy birthday greeting in Hindi", true)}>
                        {"जन्मदिन की शुभकामनाएं"}
                    </Button> */}
                    {/* <Button  onClick={() => handleClick("Chandrayaan 3 objectives", true)}>
                        <div>Chandrayaan 3 Objectives</div>
                    </Button> */}
                   
                    

                    
{/*                     
                    <Button style={{background:"#EFEAE9", fontSize:"0.6em", color:"#13343b",border:"none", fontWeight:"700"}} 
                            onClick={() => {
                                handleClick("rakhi", true);
                            }}>
                    {"Write a Raksha bandhan greeting with emoji 🎉"}
                    </Button> */}
                    
                    
                   
                    {/* <Button style={{background:"#EFEAE9", textTransform:"none", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("make in India", true)}>
                    <img src="https://digest.myhq.in/wp-content/uploads/2022/12/make-in-india-dream.jpg" style={{height:"150px"}}/>
                    <div>What is Make in India?</div>
                        </Button>  */}
                    {/* <Button style={{background:"#EFEAE9", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("Peacock Photos", true)}>
                    <img src="https://funnyexpo.com/wp-content/uploads/2018/01/Beautiful-Picture-of-Peacock-16-2.jpg" style={{height:"150px"}}/>
                        <div>Peacock Photos</div>
                    </Button> */}
                    {/* <Button style={{ background: "#EFEAE9", fontSize: "0.62em", color: "#13343b", border: "none", fontWeight: "700" }} onClick={() => handleClick("Images of pragyaan rover", true)}>
                    <img src="https://static.theprint.in/wp-content/uploads/2019/07/Pragyan-Rover-1024x683.jpg" style={{height:"150px"}}/>
                        <div>Chandrayaan, Images of Pragyaan rover</div>
                    </Button> */}
                    

                    
                   
                   
{/*                    
                    <br/> */}
                    

                    
                    
                    {/* <Button style={{background:"lightgray", fontSize:"0.62em", color:"#1d1d1f",border:"none", fontWeight:"500"}} onClick={() => handleClick("Write a short tweet on Chandrayaan 3 with hashtags")}>
                        {"Write a tweet on Chandrayaan 3 🚀"}
                    </Button> */}
                    
                    
            {/* </div> */}
            
            
            {/* <Grid container spacing={2} justifyContent={"center"}> */}
                {/* <Grid item>
                    <MedicalInformation style={{width:"50px", height:"50px"}}/>
                    <Typography variant="body2">Home</Typography>
                    <Typography variant="body2">Remedies</Typography>
                </Grid> */}
                {/* <Grid item style={{textAlign:"center"}} onClick={(_)=>navigate("/gk")}>
                    <SchoolIcon style={{width:"50px", height:"50px"}}/>
                    <Typography style={{opacity: 0.7, fontSize:"0.75rem"}} variant="body2">General Knowledge</Typography>
                </Grid> */}
                {/* <Grid item style={{textAlign:"center"}}>
                    <CurrencyRupeeIcon style={{width:"50px", height:"50px"}}/>
                    <Typography style={{opacity: 0.7, fontSize:"0.75rem"}} variant="body2">Market Prices</Typography>
                </Grid> */}
                
                {/* <Grid item>
                    <PlaceIcon style={{width:"50px", height:"50px"}}/>
                    <Typography variant="body2">Climate Data</Typography>
                </Grid> */}
                {/* <Grid item style={{textAlign:"center"}} onClick={(_)=>navigate("/gk")}> 
                    <CreateIcon style={{width:"50px", height:"50px"}}/>
                    <Typography style={{opacity: 0.7, fontSize:"0.75rem"}} variant="body2">(Beta) Write with AI</Typography>
                </Grid>
                 */}
            {/* </Grid> */}
            {/* <div style={{margin: "5%"}}><i style={{fontSize:"smaller"}}>*BharatChat is powered by AI, so mistakes are possible. Please review the generated answeres and references carefully. </i></div> */}

            {/* <hr style={{ width: "100%", marginTop:"7%", marginBottom:"7%"}} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "5%" }}>
                
                    <img style={{ width: "189px", height: "110px" }} src="https://digest.myhq.in/wp-content/uploads/2022/12/make-in-india-dream-1.jpg" alt="Home" />
                        <Button style={{background:"#EFEAE9", textTransform:"none", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700", marginBottom:"2%", marginTop:"2%"}} onClick={() => handleClick("bharatchat", true)}>
                            {"What is BharatChat.ai?"}
                        </Button>
                        
                        <Button style={{background:"#EFEAE9", textTransform:"none", fontSize:"0.62em", color:"#13343b",border:"none", fontWeight:"700"}} onClick={() => handleClick("make in India", true)}>
                            {"What is Make in India?"}
                        </Button> 
            </div>
            <hr style={{ width: "100%", marginTop:"7%", marginBottom:"7%"}} /> */}

            <div>
            
            {/* <div style={{paddingTop:"15%",paddingBottom:"5%", display:"flex", alignItems:"center"}}> */}
               {/* <DevicesOtherIcon/> */}
               {/* <div style={{paddingLeft:"2%", fontSize:"1.1em"}}><p style={{color:"rgb(52, 52, 52)", fontWeight:"bold"}}>Learn More or </p> <div className="className='prompt_assistance_button_group'"><Button onClick={() => handleClick("Chat")}>Chat with me </Button> about ChatDragon.ai</div>
                </div>  */}
                {/* <div className='prompt_assistance_button_group' style={{paddingTop:"0.3%"}}><Button onClick={() => handleClick("Chat")}>(Beta) Chat about ChatDragon.ai </Button></div> */}
            </div>

            {/* <div style={{paddingTop:"15%",paddingBottom:"8%", display:"flex", alignItems:"center"}}> */}
            {/* <div style={{paddingLeft:"2%", fontSize:"1.1em"}}><p style={{color:"rgb(52, 52, 52)", fontWeight:"450", fontSize:"26px"}}>Learn More</p></div>  */}
                        {/* <Button style={{marginLeft:"2%", background:"whitesmoke", textTransform:"none", fontSize:"0.73em", color:"#13343b",border:"none", letterSpacing:"0.09em"}} onClick={() => {
                            dispatch({
                                type: actionTypes.SET_CHAT_MODE,
                                chatMode: true // or derived data from it
                              });
                            handleClick("Chat")
                        }}>
                            {"Chat with us about ChatDragon.ai"}
                        </Button>  */}
            {/* </div> */}
            {/* <div style={{alignItems:"center"}}>
            <iframe width="330" height="315" src="https://www.youtube.com/embed/YDhk942MH8Y?si=mG9QSRYNlo08LsCt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> */}
            {/* <Button style={{margin:"auto", display:"flex", marginTop:"2%", background:"whitesmoke", textTransform:"none", fontSize:"0.74em", fontWeight:"420", color:"#1d1d1f",border:"none", letterSpacing:"0.09em"}} onClick={() => {
                            dispatch({
                                type: actionTypes.SET_CHAT_MODE,
                                chatMode: true 
                              });
                            handleClick("Chat")
                        }}>
                            {"Chat about ChatDragon.ai"}
                        </Button>  */}
                

                { !user && <div style={{textAlign:"center"}}>
                
                 {/* <Button style={{fontSize:"0.74em", textTransform:"inherit", padding:"5px 20px 5px 20px", background:"black",opacity:"0.8"}} variant="contained" onClick={_=> navigate("/login")}>Sign In to use the worlds most poweful AI</Button> */}
                 {/* <div style={{paddingLeft:"2%", fontSize:"1.1em", margin:"auto"}}><p style={{color:"rgb(52, 52, 52)", fontWeight:"450", fontSize:"16px"}}>Sign in to use the Worlds most powerful AI Model</p></div> */}
            </div>}
            {/* <div className='prompt_assistance_button_group' style={{marginBottom:"20%"}}>
                    <Button  onClick={() => handleClick("productivity", true)}>
                        {"Write a section on how AI can help boost the productivity of an organization"}
                    </Button>
                    <Button  onClick={() => handleClick("benefits", true)}>
                        {"Write an article about economic benefits of a four day work week"}
                    </Button>
                    <Button  onClick={() => handleClick("Write a monthly status report")}>
                        {"Write a monthly status report"}
                    </Button> */}
                    {/* <Button  onClick={() => handleClick("Create a job description")}>
                        {"Create a job description for a Senior Designer"}
                    </Button> */}
                   
                    {/* <Button  onClick={() => handleClick("Write a blog about camping in Dehradun")}>
                        {"Write a blog about camping in Dehradun"}
                    </Button> */}
            {/* </div> */}
            {/* <div className='prompt_assistance_button_group'>
                    <Button onClick={() => handleClick(promptExamples[2].term)}>
                        {promptExamples[2].label}
                    </Button>
                  
            </div> */}
            {/* <div className='prompt_assistance_button_group'>
                   <Button onClick={() => handleClick("story", true)}>
                        {"Write a short story for children"}
                    </Button>
                    <Button onClick={() => handleClick("Write a Cover Letter for a Software Engineering role")}>
                        {"Cover Letter for a Software Engineering role"}
                    </Button>
                    <Button onClick={() => handleClick("Write a Happy Thanksgiving greeting for friend")}>
                        {"Write a Happy Thanksgiving greeting for friend"}
                    </Button>
                    
                    
            </div> */}
            {/* <div className='prompt_assistance_button_group'>
                    <Button onClick={() => handleClick(promptExamples[6].term)}>
                        {promptExamples[6].label}
                    </Button>
                    <Button onClick={() => handleClick(promptExamples[7].term)}>
                        {promptExamples[7].label}
                    </Button>
            </div> */}
            {/* <div className='prompt_assistance_button_group'>
                    <Button onClick={() => handleClick("Write 3 paragraphs on US Elections")}>
                        {"Write 3 paragraphs on US Elections"}
                    </Button>
                    <Button onClick={() => handleClick("Write an inspirational message")}>
                        {"Write an inspirational message"}
                    </Button>
                    <Button onClick={() => handleClick("Write a happy birthday greeting in Hindi", true)}>
                        {"जन्मदिन की शुभकामनाएं"}
                    </Button>
                    
            </div> */}
            {/* <div className='prompt_assistance_button_group'>
                    <Button onClick={() => handleClick("Write a short story for children")}>
                        {"Write a short story for children"}
                    </Button>
                    <Button onClick={() => handleClick("Write a letter asking manager for a promotion")}>
                        {"Write a letter asking manager for a promotion"}
                    </Button>
            </div> */}
            {/* </div> */}
            {/* <div style={{paddingTop:"20%",paddingBottom:"5%", display:"flex", alignItems:"center"}}> */}
               {/* <AnnouncementIcon/> */}
               {/* <div style={{paddingLeft:"2%", fontSize:"1.1em"}}><p style={{color:"rgb(52, 52, 52)", fontWeight:"bold"}}>Latest news with AI</p></div>  */}
            {/* </div> */}
            {/* <div className='prompt_assistance_button_group' style={{paddingBottom:"1%"}}> */}
            {/* <Button onClick={() => handleClick("Images of pragyaan rover", true)}>
                    <img src="https://static.theprint.in/wp-content/uploads/2019/07/Pragyan-Rover-1024x683.jpg" style={{height:"150px"}}/>
                        <div>Chandrayaan, Images of Pragyaan rover</div>
                    </Button>
                    <Button onClick={() => handleClick("Asia Cup 2023, Pakistan vs Nepal AI Match summary")}>
                         Asia Cup 2023, Pakistan vs Nepal match AI summary
                    </Button>
                    <Button onClick={() => handleClick("Match Summary")}>
                        {"Match Summary"}
                    </Button> */}
            {/* </div> */}
            {/* <div className='prompt_assistance_button_group' style={{paddingBottom:"1%"}}> */}
            
                   {/* <Button onClick={() => handleClick("Gadar 2 review")}>
                        {"Gadar 2 review"}
                    </Button>
                    <Button onClick={() => handleClick("BigBoss OTT 2 Winner")}>
                        {"BigBoss OTT 2 Winner"}
                    </Button>
                    <Button onClick={() => handleClick("OMG 2 review")}>
                        {"OMG 2 review"}
                    </Button>
                    
                    <Button onClick={() => handleClick("Neymar Quits PSG")}>
                        {"Neymar Quits PSG"}
                    </Button> */}
                    
                    {/* <Button style={{"background":"dimgray"}} onClick={() => handleClick("Rajnath Singh: Indian army will respond to any threat")}>
                        {"Rajnath Singh: Indian army will respond to any threat"}
                    </Button> */}
            {/* </div> */}
            
            {/* <div style={{paddingTop:"6%",paddingBottom:"3%", display:"flex", alignItems:"center"}}>
               <AdsClickIcon/>
                <div style={{paddingLeft:"1%"}}><strong>Astrology</strong>, Tap for AI insights</div> 
            </div>
            <div className='prompt_assistance_button_group' style={{paddingBottom:"1%"}}>
                    
                    <Button style={{"background":"teal"}} onClick={() => handleClick("Vrishchik Lagna August Predictions")}>
                        {"Vrishchik Lagna August Predictions"}
                    </Button>
                    <Button style={{"background":"teal"}} onClick={() => handleClick("Karka Lagna August Predictions")}>
                        {"Karka Lagna August Predictions"}
                    </Button>
            </div> */}

            {/* <div style={{paddingTop:"15%",paddingBottom:"8%", display:"flex", alignItems:"center"}}>
               <div style={{paddingLeft:"2%", fontSize:"1.1em", margin:"auto"}}><p style={{color:"rgb(52, 52, 52)", fontWeight:"450", fontSize:"36px"}}>Beautiful AI Generated Images</p></div> 
           </div> */}

            {/* <div style={{paddingTop:"20%",paddingBottom:"10%", display:"flex", alignItems:"center"}}>
               
               <div style={{paddingLeft:"2%", fontSize:"1.1em", margin:"auto"}}><p style={{color:"rgb(52, 52, 52)", fontWeight:"450",  fontSize:"36px"}}>Compare Products</p></div> 
            </div> */}
            {/* <div className='prompt_assistance_button_group' style={{paddingBottom:"8%"}}>
                    
                   <Button onClick={() => handleClick("review", true)}>
                        {"AI Review: Xiaomi Redmi 12"}
                    </Button>
                    <Button onClick={() => handleClick("Compare Samsung Galaxy S22 vs iPhone 14, give a detailed pros and cons in a table with latest price")}>
                        {"Samsung Galaxy S22 vs iPhone 14"}
                    </Button>
                    <Button onClick={() => handleClick("Compare the top 2 Bose vs Sony noise cancelling headphones, give a detailed pros and cons in a table with latest price")}>
                        {"Bose vs Sony noise cancelling headphones"}
                    </Button>
                    <Button onClick={() => handleClick("Compare Xiaomi Redmi 12 vs Motorola Moto G14, give a detailed pros and cons in a table with latest price")}>
                        {"Xiaomi Redmi 12 vs Motorola Moto G14"}
                    </Button>
                    <Button onClick={() => handleClick("Compare Samsung Galaxy F04 vs Realme Narzo N53, give a detailed pros and cons in a table with latest price")}>
                        {"Samsung Galaxy F04 vs Realme Narzo N53"}
                    </Button>
                   
            </div> */}
            {/* <div style={{paddingTop:"20%",paddingBottom:"5%", display:"flex", alignItems:"center"}}>
               <div style={{paddingLeft:"2%", fontSize:"1.1em"}}><p style={{color:"rgb(52, 52, 52)", fontWeight:"bold"}}>Ask AI anything</p></div> 
            </div>
            <div className='prompt_assistance_button_group' style={{paddingBottom:"1%"}}>
            <Button  onClick={() => handleClick("Helpline number ambulance in Gurgaon")}>
                         Helpline numbers ambulance in Gurgaon
                    </Button>
                    
                    <Button onClick={() => handleClick("How to update Aadhar card phone number?")}>
                        {"How to update Aadhar card phone number?"}
                    </Button>
                    <Button  onClick={() => handleClick("How to use Paytm?")}>
                        {"How to use Paytm?"}
                    </Button>
                    <Button onClick={() => handleClick("helpline number railway booking update")}>
                         Helpline number railway booking update
                    </Button>
                    <Button onClick={() => handleClick("How to get Police Verification for passport in India?")}>
                        {"How to get Police Verification for passport?"}
                    </Button>
                    <Button 
                            onClick={() => {
                                handleClick("Top 5 highest paid actors in India");
                            }}>
                        {"Top 5 highest paid actors in India"}
                    </Button>
                    <Button onClick={() => handleClick("Next 5 day weather forecast in Delhi")}>
                        {"Next 5 day weather forecast in Delhi"}
                    </Button>  */}
                   
            {/* </div> */}
            {/* <div style={{paddingTop:"15%", paddingBottom:"5%", display:"flex", alignItems:"center"}}>
               <AdsClickIcon/>
                <div style={{paddingLeft:"2%", fontSize:"1.1em"}}><strong>Finance (Beta)</strong></div> 
            </div>
            
            <div className='prompt_assistance_button_group' style={{paddingBottom:"1%"}}>
                   <Button style={{"background":"#EFEAE9"}} onClick={() => handleClick("Stock Market highlights for today")}>
                        {"Stock Market highlights for today"}
                    </Button>
            </div>
            <div className='prompt_assistance_button_group' style={{paddingBottom:"1%"}}>
                    
                    <Button style={{"background":"#EFEAE9"}} onClick={() => handleClick("Infosys Ltd Stock Price")}>
                        {"Infosys Ltd Stock Price"}
                    </Button>
                    <Button style={{"background":"#EFEAE9"}} onClick={() => handleClick("Reliance Industries Ltd Stock Price")}>
                        {"Reliance Industried Ltd Stock Price"}
                    </Button>
            </div> */}
             {/* <div style={{paddingTop:"20%",paddingBottom:"5%", display:"flex", alignItems:"center"}}>
               <div style={{paddingLeft:"2%", fontSize:"1.1em"}}><p style={{color:"rgb(52, 52, 52)", fontWeight:"bold"}}>Complete my writing with AI</p></div> 
             
            </div> */}
    </div>
    );
};

export default LandingPrompts;


/**
 * 
 * 
 */

