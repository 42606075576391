import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

export default function MultiActionVideoCard({url}) {
  const navigate = useNavigate();
  return (
    <>
    <Card sx={{ boxShadow:"2px 2px 2px 2px rgba(0,0,0,0), 2px 2px 2px 2px rgba(0,3,1,0.14), 2px 2px 2px 2px rgba(0,0,0,0.12)" }} onClick={(_)=>navigate("/snap")}>
      <CardActionArea>
       {/* <CardMedia
          component="img"
          image={image}
          alt="green iguana"
        />  */}
        <iframe width="377" height="200" src="https://www.youtube.com/embed/27prCUrKNBM?si=WHp7Z3JW2C9uYapo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: "'bitter', serif" }}>
            Dunki Opens to mixed reviews
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
          "Dunki," a visually stunning film ...,
          </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions style={{ justifyContent: "space-between" }}>

        <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }}>
        <StickyNote2Icon style={{fontSize:"20px", opacity:1, marginTop:"1px", padding:"5px"}}/> AI Summary 
        </Button>

        <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }}>
        <SearchIcon style={{fontSize:"20px", opacity:1, marginTop:"1px", padding:"3px"}}/> Discuss with AI 
        </Button>
        {/* <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }} onClick={(_)=>window.location.assign("url")}>
          Read Article
        </Button> */}
      </CardActions>

    </Card>
    <br/>
    <br/>
    </>
  );
}
