// const basePaypalUrl = "http://localhost:8890";
// export const cancelPaypalUrl = `${basePaypalUrl}/api/paypal/cancelSubscription`;

//backend orchestrator
export const orchestratorBaseUAT = "http://localhost:8080";


//Text-generation apis
export const textGenerationBaseUAT = orchestratorBaseUAT;
export const textGenerationMultiturnUAT = `${orchestratorBaseUAT}/multi`;
export const textGenerationBaseFollowupUAT = `${orchestratorBaseUAT}/question`;

export const gptUrlUAT = `${orchestratorBaseUAT}/gpt`

export const gptServiceUrlUAT = `${orchestratorBaseUAT}/gpt-service`;
export const gptServiceUrlGenericUAT = `${orchestratorBaseUAT}/gpt-service-generic`;
export const gptServiceUrlMedicalUAT = `${orchestratorBaseUAT}/gpt-service-medical`;
export const gptServiceUrlLocationUAT = `${orchestratorBaseUAT}/gpt-service-location`;

export const gptServiceMedicalUrlUAT = `${orchestratorBaseUAT}/gpt-medical-service`;

//frontend
const frontendUAT = "http://localhost:3000";

//Authentication - direct provider
export const redirectUriUAT = `${frontendUAT}/login`;

//Authentication - backend
export const endAuthSessionUAT = `${orchestratorBaseUAT}/endSession`;
export const sessionUserUAT = `${orchestratorBaseUAT}/user`;
export const fetchUserUAT = `${orchestratorBaseUAT}/getAuthenticatedUser`;

//Payment Providers

//Paypal
export const paymentBaseUAT = "http://localhost:8890";
export const cancelPaypalSubscriptionUrlUAT = `${paymentBaseUAT}/api/paypal/cancelSubscription`;
export const createPaypalSubscriptionUAT = `${paymentBaseUAT}/api/paypal/create-subscription`;
export const subscriptionDetailUrlUAT = `${paymentBaseUAT}/api/paypal/getSubscriptionDetail`;

//stripe
export const createStripeCheckoutSessionUAT = `${paymentBaseUAT}/create-checkout-session`;