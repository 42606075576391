import React from "react";
import "./Short.css";
import { Card, CardActionArea, CardMedia, CardContent, Typography, CardActions, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AppFooter from "./AppFooter";
import {useNavigate} from "react-router-dom"
import { clientCache } from "../mock/ClientCache";
import { actionTypes } from "../statemanagement/reducer";
import { useStateValue } from "../statemanagement/StateProvider";

const MultilingualShort = ({ imageUrl, name, description }) => {

    const navigate = useNavigate();
    const [_, dispatch] = useStateValue();

    return (
        <div className="short">
            <Card
                raised
                sx={{ height: "100vh" }}
            >
                <CardContent sx={{height:"70vh"}}>
                    <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: "'bitter', serif" }}>
                    மோடியின் தெலங்கானா விஜயம்
                    </Typography>
                    {/* <CardMedia
                        component="img"

                        image={imageUrl}
                        alt={"alt"}
                        sx={{ height: "40%", padding:"10px"  }}
                    /> */}
                    <br/>
                    <iframe width="357" height="200" src="https://www.youtube.com/embed/QZiXTEByw3s?si=-pjibSTpAS-JwhdW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <br />
                    <Typography variant="body2" color="text.secondary" sx={{padding:"15px"}}>
                        {`பிரதமர் நரேந்திர மோடி, தெலங்கானாவுக்கு ஒரு முக்கிய விஜயம் செய்தார், அங்கு அவர் பல்வேறு திட்டங்களை துவங்கி வைத்தும், சில திட்டங்களை தேசத்துக்கு அர்ப்பணித்தும் இருந்தார். ஹைதராபாத்தில் அவர் வந்தார், அங்கு அவர் செகண்டராபாத்-திருப்பதி வண்டே பாரத் எக்ஸ்பிரஸை கொடியேற்றி வைத்தார், மற்றும் பாரேட் மைதா. பிரதமர் நரேந்திர மோடி அவர்கள் தெலங்கானாவில் ஒரு அதிசயம் செய்துள்ளனர். அவர் அந்த முக்கிய நிகழ்ச்சியில் பல சிறந்த பேர்களை உணர்த்தி, பல முக்கிய திட்டங்களை அதிரசப்தமாக துவங்கி வைத்துள்ளார். அதன் மூலம், தெலங்கானாவை முழுமையாக அர்ப்பணிப்பதில் அவர் திகழ்ந்திருந்தார்.

அவர் ஹைதராபாத் நகரில் வருந்தினார், அங்கு அவர் செகண்டராபாத்-திருப்பதி வண்டே பாரத் எக்ஸ்பிரஸை கொடியேற்றி வைத்தார். இதன் மூலம், ரெயில் வழங்கிய சேவை மேம்படுத்தப்பட்டது மற்றும் பருவம் பருவம் வந்த பாரேட் மைதாக்களை விருதுப்படுத்தினார்.`}
                    </Typography>
                    
                </CardContent>
                <CardActions style={{ justifyContent: "space-between", height:"30vh" }}>
        
                        <Button onClick={(_)=>{
                            setTimeout(()=>{
                                dispatch({
                                    type: actionTypes.UPDATE_CONVERSATION_HISTORY,
                                    enrichedConversationHistory: clientCache(`David Warner's Impressive Comeback: Australian Cricket Legend Urges Him Not to Retire Yet`) 
                                  });
                            }, 50);

                            navigate("/query");
                        }} size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }}>
                        <SearchIcon style={{fontSize:"20px", opacity:1}}/> Discuss with AI 
                        </Button>
                        <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }}>
                        Read Article
                        </Button>
                    </CardActions>
                
            </Card>
            <AppFooter/>
        </div>
    );
};

export default MultilingualShort;
//https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703158664218_GByxluKXwAAwslW.jpeg





/**
 * <Card sx={{ height: "100%" }}>
        <CardActionArea>

          <CardMedia
            sx={{ width: desiredWidth, height: desiredHeight }}
            component={"img"}
            image="https://i.ytimg.com/vi/8IOmIAk-c7o/maxresdefault.jpg"
          />
        </CardActionArea>
      </Card>
 */