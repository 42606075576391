import React from "react";
import { useStateValue } from '../statemanagement/StateProvider';
import "./BasicChat.css"
import { useState } from "react";
import Header from "./Header";
import AppFooter from "./AppFooter";
import InAppSearch from "../components/InAppSearch";
import { TypeAnimation } from "react-type-animation";

function SearchExperience() {
    const [index, setIndex] = useState(0);
    const [{}, dispatch] = useStateValue();

    return (
        <div>
            <div className="container">
                <Header />
                <div className="container_body">
                    <div className="container_copy" style={{paddingTop:"20%"}}>
                        <TypeAnimation
                                    sequence={["Ask AI anything"]}
                                    wrapper="span"
                                    speed={25}
                                    style={{
                                    fontSize: "1.`rem",
                                    lineHeight: "1.8`rem",
                                    display: "inline-block",
                                    color: "#000",
                                    fontWeight: "400",
                                    }}
                                    repeat={0}
                                />
                    </div>
                    <InAppSearch home={true} showButtons={true} mode={"landing"} placeholder={""} route={true} style={{ fontWeight: 400 }} />
                </div>
            </div>
            <AppFooter/>
        </div>
    );
}
export default SearchExperience;
