import React from 'react'
import { Box, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CustomButton from "./CustomButton";
import "./BasicChat.css"
import { TypeAnimation } from 'react-type-animation';
import { useNavigate } from 'react-router-dom';

import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';

const GenericSearch = ({typeAnimationList, secondaryText="Created by ex-Microsoft AI Copilot creators", animationFont, icon, showSecondaryText=false, mode="generic",
    override=false, disclaimer = "*BharatChat is powered by AI, so mistakes are possible. Please review the generated text carefully."}) => {
    const location = useNavigate();

    //pull stuff from the data layer
    const [{_}, dispatch] = useStateValue();

    const CustomBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(5),
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    }));

    const CustomTypeAnimationBox = styled(Box)(({ theme }) => ({
        fontSize: "1.7rem",
        lineHeight: "1.8rem",
        display: "inline-block",
        color: "#000",
        fontWeight: "500",
        '& .type-animation': {
            whiteSpace: "nowrap",
        },
        marginTop: theme.spacing(5), 
        marginBottom: theme.spacing(1)
    }));

    return (
        <Box sx={{ backgroundColor: "#fff" }}>
            <Container>
                <CustomBox>
                
                    <Box sx={{ flex: "1" }}>
                    
                        <CustomTypeAnimationBox>
                        
                        {
                        (typeAnimationList.length > 1 || override) ? (
                                <TypeAnimation
                                    sequence={typeAnimationList}
                                    wrapper="span"
                                    speed={25}
                                    style={{
                                    fontSize: animationFont,
                                    lineHeight: "1.8rem",
                                    display: "inline-block",
                                    color: "#000",
                                    fontWeight: "400",
                                    }}
                                    repeat={0}
                                />
                                ) : (
                                <>
                                {!!icon && icon}
                                <Typography
                                    variant="body2"
                                    sx={{ fontSize: "23px", color: "#000", my: 5 }}
                                >
                                    {typeAnimationList[0]}
                                </Typography></>) }

                        </CustomTypeAnimationBox>
                       {showSecondaryText && <Typography
                            variant="body2"
                            sx={{ fontSize: "21px", color: "#000", my: 5 }}
                        >
                            {secondaryText}
                        </Typography>}
                        <CustomButton
                            backgroundColor="#000"
                            color="#fff"
                            buttonText="Try now, Use BharatChat.ai for free"
                            heroBtn={true}
                            onClick={() => {
                                dispatch({
                                    type: actionTypes.SET_APP_MODE,
                                    appMode: mode
                                })
                                location(`/basic`)
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{ fontSize: "18px", color: "#5A6473", my: 5 }}
                        >
                            <i>{disclaimer}</i>
                        </Typography>
                    </Box>
                </CustomBox>
            </Container>
        </Box>
    )
}

export default GenericSearch