export const cachedBingNewsHeadlines = [
    {
        "name": "Aakash Chopra Analyzes India's Hasty Cricket Selections",
        "url": "https://sports.ndtv.com/cricket/on-indian-cricket-team-having-21-odi-debuts-since-2021-aakash-chopras-trigger-happy-verdict-4691244",
        "image": {
            "contentUrl": "https://c.ndtvimg.com/2023-12/v9cklkt8_sada_625x300_17_December_23.jpg?im=FeatureCrop,algorithm=dnn,width=1200,height=675",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.mA9___5k6CZnJ36PeA4IOi&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "Aakash Chopra believes that the Indian cricket team management has been quite 'trigger happy' when it comes to choosing players for ODIs.",
        "provider": [
            {
                "_type": "Organization",
                "name": "NDTV",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.4noS8q5D3eOJne8uwNJWvg&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T09:26:00.0000000Z",
        "category": "Sports"
    },
    {
        "name": "Legend Warns Warner: Don't Retire Amidst Comeback!",
        "url": "https://www.msn.com/en-gb/sport/other/australia-cricket-legend-urges-david-warner-not-to-retire/ar-AA1lFFu4",
        "image": {
            "contentUrl": "https://i.dailymail.co.uk/1s/2023/12/18/09/79078733-0-image-m-16_1702893100955.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.UGjXGFVTUmVrM2DrhWKqdi&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "Warner, 37, silenced his critics with a 164-run first innings knock in the Perth Test, emphasising his credentials amid huge debate around his desire for a Sydney swansong.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/e8bdc3d4-c6d1-805c-ddb2-5bc7b1a8a8f7",
                "name": "David Warner"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/93a3e0e2-aecf-e53b-8432-c15f400cfb4e",
                "name": "Daily Mail"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/69cd8ccc-adba-8eeb-a48e-3e7229e9666c",
                "name": "Pakistan"
            }
        ],
        "mentions": [
            {
                "name": "David Warner"
            },
            {
                "name": "Daily Mail"
            },
            {
                "name": "Pakistan"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "Daily Mail on MSN.com",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF._kZMPt4WNJFUpf58U6Qe-w&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T09:52:01.0000000Z",
        "category": "Sports"
    },
   
    {
        "name": "PM Modi Pushes for Water, Cleanliness in India",
        "prompt":"srk",
        "url": "https://www.msn.com/en-in/news/India/from-water-conservation-to-cleanliness-pm-puts-forth-9-resolutions-9-requests/ar-AA1lFnSd",
        "image": {
            "contentUrl": "https://c.ndtvimg.com/2023-12/d28i0llo_pm-modi-ani_625x300_18_December_23.jpeg?ver-20231203.06",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.tP2QnA7VyvrRcC2tgGaFHS&pid=News",
                "width": 700,
                "height": 430
            }
        },
        "description": "The PM proposed these ideas while addressing the inaugural function of the Swarved Mahamandir on the second and final day of his visit to his parliamentary constituency Varanasi.",
        "provider": [
            {
                "_type": "Organization",
                "name": "Press Trust of India on MSN.com",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.f7R6GuHbJOtbCCYdIL4qvA&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T10:15:00.0000000Z",
        "category": "World"
    },
   
    {
        "name": "British MP Fights for Free Test Cricket TV",
        "url": "https://www.msn.com/en-xl/news/other/mp-bids-to-get-england-test-cricket-free-on-tv/ar-AA1lFQ5Q",
        "image": {
            "contentUrl": "https://ichef.bbci.co.uk/news/1024/branded_news/4400/production/_132080471_5734b9270d98beb48c8af14d64f5a53657cfa66d.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.kq1JutMWByid2eTQTvldxC&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "Live coverage of England's home Test cricket matches would be shown on free-to-air TV under a proposed law change. Six Nations rugby matches involving home countries would be given the same status by the bill, submitted by former Labour minister John Spellar.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/d8c12054-a38a-14f5-8f22-49ff5614aeb5",
                "name": "BBC News"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/dda74e0d-4c4c-b30a-3b06-9bd5c083a1e4",
                "name": "England cricket team"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "BBC on MSN.com",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.k3N4DqozNjgK376rAnFmsA&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T12:28:01.0000000Z",
        "category": "Sports"
    },
    {
        "name": "ICC World Cup 2023: Moments, Records Highlighted",
        "url": "https://sports.ndtv.com/cricket/icc-world-cup-2023-rewind-reminiscing-incredible-feats-and-records-of-odi-cricket-4689974",
        "image": {
            "contentUrl": "https://c.ndtvimg.com/2023-11/httcslo_team-australia-afp_625x300_20_November_23.jpg?im=FeatureCrop,algorithm=dnn,width=1200,height=675",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.P4CN4xY6viQ31RfHUcHDsy&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "ICC Cricket World Cup culminated with Australia claiming their sixth title by defeating India in the final at the Narendra Modi Stadium in Ahmedabad.",
        "provider": [
            {
                "_type": "Organization",
                "name": "NDTV",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.4noS8q5D3eOJne8uwNJWvg&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T04:09:00.0000000Z",
        "category": "Sports"
    },
    {
        "name": "Gilchrist's New Role Strengthens Australia-India Ties",
        "url": "https://www.msn.com/en-gb/sport/cricket/aussie-cricket-legend-adam-gilchrist-announces-his-big-new-career-move/ar-AA1lF1QX",
        "image": {
            "contentUrl": "https://i.dailymail.co.uk/1s/2023/12/18/05/79074345-0-image-a-105_1702877937262.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.iC5mquW79dIkKrhETDIosi&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "Aussie cricket icon Adam Gilchrist has a surprising new job with the Centre for Australia-India Relations as an advisory board member - which he will juggle with his other commitments.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/fa1d9c1e-3812-64dc-d452-4036829595d9",
                "name": "Adam Gilchrist"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/93a3e0e2-aecf-e53b-8432-c15f400cfb4e",
                "name": "Daily Mail"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/06de2191-243d-a83f-6990-2eb1c7f3382a",
                "name": "Australia"
            }
        ],
        "mentions": [
            {
                "name": "Adam Gilchrist"
            },
            {
                "name": "Daily Mail"
            },
            {
                "name": "Australia"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "Daily Mail on MSN.com",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF._kZMPt4WNJFUpf58U6Qe-w&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T06:24:18.0000000Z",
        "category": "Sports"
    },
    {
        "name": "Mamata Banerjee: INDIA Bloc Seat Deal Tuesday",
        "url": "https://www.livemint.com/politics/news/india-bloc-should-finalize-seat-sharing-arrangement-on-tuesday-mamata-banerjee-11702909640110.html",
        "image": {
            "contentUrl": "https://www.livemint.com/lm-img/img/2023/12/18/1600x900/ANI-20231217180-0_1702911409903_1702911432482.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.kKh93O9BHMkUmOQesWNeiS&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "The seat sharing issue is one of the tricky questions in the whole INDIA alliance equation and its resolution will decide the strength of the Opposition alliance which is going against the popular BJP",
        "provider": [
            {
                "_type": "Organization",
                "name": "Mint",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.W8vM8p2PIvfc6Sh2F0nZpA&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T15:06:00.0000000Z",
        "category": "World"
    },
    {
        "name": "2023's Top Political Drama: Bharat vs India",
        "url": "https://www.livemint.com/news/from-bharat-vs-india-to-mahua-moitra-a-recap-of-top-political-controversies-of-2023-year-ender-11702742592278.html",
        "image": {
            "contentUrl": "https://www.livemint.com/lm-img/img/2023/12/16/1600x900/PTI12-08-2023-000183A-0_1702745818091_1702745840961.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.PPC7uGKfFd9Vxmpk2esnQy&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "The year 2023 witnessed several controversies that made headlines in Indian politics, including the India ‘renaming’ controversy, Mahua Moitra cash-for-query. Here's a recap of the top",
        "provider": [
            {
                "_type": "Organization",
                "name": "Mint",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.W8vM8p2PIvfc6Sh2F0nZpA&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-17T04:29:00.0000000Z",
        "category": "World"
    },
    {
        "name": "Mamata's Left Turn Before INDIA Meet Strategy",
        "url": "https://www.news18.com/politics/left-can-also-be-right-ahead-of-india-meet-mamata-mellows-towards-sworn-enemy-to-keep-bjp-at-bay-8708887.html",
        "image": {
            "contentUrl": "https://images.news18.com/ibnlive/uploads/2023/10/untitled-design-1-2023-10-9650bcb2db4eb08ab142933c5ae2e4d6-16x9.jpg?impolicy=website&width=1200&height=675",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.ordIixc4JK9mSsY34Qd6wS&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "When asked specifically by News18 about the possibility of a triangular alliance between the TMC, Congress and Left for the Lok Sabha polls, Mamata Banerjee said that she is open to talks and bears no",
        "provider": [
            {
                "_type": "Organization",
                "name": "CNN-News18",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.raV3hWx5LJpFUHNtCFAcrw&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T16:03:00.0000000Z",
        "category": "Politics"
    },
    {
        "name": "India's Youth Struggle: Is Politics to Blame?",
        "url": "https://www.business-standard.com/opinion/columns/no-jobs-and-a-bleak-future-has-the-political-class-failed-india-s-youth-123121800092_1.html",
        "image": {
            "contentUrl": "https://bsmedia.business-standard.com/_media/bs/img/article/2023-12/13/full/1702490995-9536.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.0GlQ8Fja48HVupzTgo237y&pid=News",
                "width": 700,
                "height": 525
            }
        },
        "description": "Rahul Gandhi has been criticised for \"trivialising\" the security breach in Parliament by belatedly raising unemployment as a political issue",
        "provider": [
            {
                "_type": "Organization",
                "name": "Business Standard",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.6yYEGE61ig4jYI6NTUmjjg&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T03:22:00.0000000Z"
    },
    {
        "name": "Annamalai Praises Modi's AI Use in Politics",
        "url": "https://timesofindia.indiatimes.com/videos/city/chennai/game-changer-in-indian-politics-annamalai-hails-pm-modi-for-using-ai-tools-for-speeches/videoshow/106087537.cms",
        "description": "Tamil Nadu BJP President hailed Prime Minister Narendra Modi on December 18 for using AI translation tools for speeches. He said this would be a game-changer in Indian politics. He said, “We all know Prime Minister is a person who is very close to technology.",
        "provider": [
            {
                "_type": "Organization",
                "name": "Indiatimes",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.VBzHmoY4z9NL3FbRonyM1w&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T09:40:00.0000000Z",
        "category": "World"
    },
    {
        "name": "India Political Highlights: Adhir Ranjan Chowdhury writes to LS Speaker to revoke suspension of 13 MPs",
        "url": "https://www.deccanherald.com/india/india-political-updates-december-17-mp-pcc-chief-says-will-strive-to-take-congress-ideology-to-every-household-bjp-karnataka-breaking-news-2814509",
        "image": {
            "contentUrl": "https://images.deccanherald.com/deccanherald%2F2023-12%2F1134a151-233e-4c4e-af02-e28b176c00a9%2FPTI12_11_2023_000039B.jpg?rect=0%2C126%2C3365%2C1767&w=1200&ar=40%3A21&auto=format%2Ccompress&ogImage=true&mode=crop",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.19GtQyOe01M-utn9ts4WwS&pid=News",
                "width": 700,
                "height": 367
            }
        },
        "description": "Leader of the Congress Party in Lok Sabha Adhir Ranjan Chowdhury writes a letter to Lok Sabha Speaker. \"Considering the factors that led to the suspension of 13 members in recent days, I would urge that the matter be re-looked into holistically and appropriate action taken for… pic.twitter.com/M56FZlFCk9",
        "provider": [
            {
                "_type": "Organization",
                "name": "Deccan Herald",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.HCNTQVDySJbiiNPGW3xUig&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-17T11:44:00.0000000Z"
    },
    {
        "name": "Decode Politics: Congress, crowdfunding, and the Mahatma",
        "url": "https://indianexpress.com/article/political-pulse/lok-sabha-polls-2024-congress-crowdfunding-9072516/",
        "image": {
            "contentUrl": "https://images.indianexpress.com/2023/12/Congress-crowdfunding-rally.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.o0m2O_9DyGNq9getwfDY5i&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "The party, nowhere close to matching the BJP in finances, has taken a leaf out of Mahatma Gandhi's playbook as it looks to generate funds ahead of the Lok Sabha elections.",
        "provider": [
            {
                "_type": "Organization",
                "name": "The Indian Express",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.KAwemXIpD5l2nQRDmYLy2w&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T13:57:00.0000000Z"
    },
    {
        "name": "Ro Khanna an inspiration for new generation of Indian Americans to join politics: Community leaders",
        "url": "https://www.tribuneindia.com/news/diaspora/ro-khanna-an-inspiration-for-new-generation-of-indian-americans-to-join-politics-community-leaders-573109",
        "image": {
            "contentUrl": "https://englishtribuneimages.blob.core.windows.net/gallary-content/2023/12/2023_12$largeimg_2098110553.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.oGqfXwmmvpEOg0_Nx3T3SS&pid=News",
                "width": 400,
                "height": 400
            }
        },
        "description": "According to the community leaders, Khanna has done critical work in strengthening the US-India partnership which is inspiring the new generation members to run for political offices",
        "provider": [
            {
                "_type": "Organization",
                "name": "The Tribune",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.eMXnJUdIZAF-K_9mwrjsXw&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T03:08:00.0000000Z"
    },
    {
        "name": "India likely to achieve fiscal deficit target in 2023-24, says govt",
        "url": "https://www.msn.com/en-us/money/companies/india-likely-to-achieve-fiscal-deficit-target-in-2023-24-says-govt/ar-AA1lFeWH",
        "image": {
            "contentUrl": "https://www.reuters.com/resizer/CnrkSqPNb-jxkH5y-CSuMbo9n1M=/1200x628/smart/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/P3QMDN7YNFMHLPDA4MZW3VM6CM.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.zuwpeW3gGIai0TqIYUDvpS&pid=News",
                "width": 700,
                "height": 366
            }
        },
        "description": "The Indian government is likely to achieve its fiscal deficit target of 5.9% of gross domestic product (GDP) in the financial year 2023-24, junior finance minister Bhagwat Karad said on Monday.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/85fa63d3-9596-adb9-b4eb-502273d84f56",
                "name": "India"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/9f417f89-9a37-e0d0-912e-99dccfb8982d",
                "name": "Reuters"
            }
        ],
        "mentions": [
            {
                "name": "India"
            },
            {
                "name": "Reuters"
            },
            {
                "name": "World"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "Reuters on MSN.com",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.jFXbg3L7Ce_1pS4_IOR8CA&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T08:22:36.0000000Z",
        "category": "ScienceAndTechnology"
    },
    {
        "name": "India's love of homegrown single malts shakes up Pernod, Diageo",
        "url": "https://www.msn.com/en-gb/foodanddrink/other/indias-love-of-homegrown-single-malts-shakes-up-pernod-diageo/ar-AA1lEf37",
        "image": {
            "contentUrl": "https://www.reuters.com/resizer/pLh9exMBA6OKnQuzPdcbduhz4Zg=/1200x628/smart/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/KIT7OBFCBZLKZCOW2QF6QCYCVI.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.2t_cOEDr-WLtSdagMxtJNi&pid=News",
                "width": 700,
                "height": 366
            }
        },
        "description": "Oak casks, once used to store bourbon and wine, are stacking up in a distillery near New Delhi, filled with ageing whisky as workers churn out almost 10,000 bottles a day of Indian single malt Indri,",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/85fa63d3-9596-adb9-b4eb-502273d84f56",
                "name": "India"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/0adb4877-d039-2563-b923-7e555468981f",
                "name": "Diageo"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/9f417f89-9a37-e0d0-912e-99dccfb8982d",
                "name": "Reuters"
            }
        ],
        "mentions": [
            {
                "name": "India"
            },
            {
                "name": "Diageo"
            },
            {
                "name": "Reuters"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "Reuters on MSN.com",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.jFXbg3L7Ce_1pS4_IOR8CA&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T01:29:56.0000000Z"
    },
    {
        "name": "Varanasi Address: PM Modi Vows to Transform India into an Economic Powerhouse in His Third Term",
        "prompt":"srk",
        "url": "https://indianexpress.com/article/india/narendra-modi-pm-third-term-india-economic-power-9073125/",
        "image": {
            "contentUrl": "https://images.indianexpress.com/2023/12/modi-varanasi.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.3kKAWtWr6blRWQ2pyyne1C&pid=News",
                "width": 700,
                "height": 393
            }
        },
        "description": "Stating that even the reconstruction of the Somnath temple was opposed after Independence, PM Modi says that 'if we had respected our cultural identity, the sense of togetherness within the country would have been strengthened'.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/6ba76e81-5253-a6ec-52cd-2c81438662fd",
                "name": "Economic power"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/04fee623-e9bd-ee4b-f30d-cad3c29199e4",
                "name": "Narendra Modi"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/a55083a8-0f4c-de67-45ca-de7e28015c11",
                "name": "The Indian Express"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "The Indian Express",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.KAwemXIpD5l2nQRDmYLy2w&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T12:24:00.0000000Z",
        "category": "LifeStyle"
    },
    {
        "name": "Congress Slams Modi Government: 'Democracy and Parliament Under Attack with MPs' Suspension'",
        "prompt":"srk",
        "url": "https://www.msn.com/en-in/news/India/govt-wants-to-bulldoze-key-bills-without-oppn-dissent-cong-after-more-mps-suspended/ar-AA1lFOXX",
        "image": {
            "contentUrl": "https://im.rediff.com/news/2023/dec/18mps-suspension.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.TixV2VoxV13pAnXYA77l2i&pid=News",
                "width": 681,
                "height": 700
            }
        },
        "description": "Soon after the suspension of 33 opposition Lok Sabha MPs, Congress president Mallikarjun Kharge said all democratic norms have been thrown into the dustbin by this 'autocratic' government and it has shown zero accountability towards Parliament.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/88003aeb-e7bf-a101-f29d-a558e695181b",
                "name": "Parliament"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/6bab3ae5-c9d8-8ae4-7759-83f2fe7b9729",
                "name": "Suspension"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "Press Trust of India on MSN.com",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.f7R6GuHbJOtbCCYdIL4qvA&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T12:13:00.0000000Z",
        "category": "World"
    },
    {
        "name": "India to Ease Century-Old Telecom Laws to Attract Investors",
        "url": "https://www.bloomberg.com/news/articles/2023-12-18/india-to-ease-century-old-telecom-laws-to-attract-investors",
        "image": {
            "contentUrl": "https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iS94PkCcmePQ/v1/1200x800.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.GazshYcWCgM1hDsvWGSXjC&pid=News",
                "width": 700,
                "height": 466
            }
        },
        "description": "India aims to reform its century-old telecommunications law by introducing a new bill as Prime Minister Narendra Modi seeks to make the sector investor friendly.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/178c7867-ab25-0abe-ed87-7855ff4b6499",
                "name": "Law"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/8ab7feb7-ef39-60c0-54cd-7b66f86d6087",
                "name": "Ease"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/7962ae3c-46a4-a659-f7d6-da89a0bb18c5",
                "name": "Investor"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "Bloomberg L.P.",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.yAnAsiiCHte5V2il_I5mug&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T14:45:00.0000000Z"
    },
   
    {
        "name": "India's Vedanta Ltd declares second interim dividend for FY24",
        "url": "https://www.msn.com/en-gb/money/other/indias-vedanta-ltd-declares-second-interim-dividend-for-fy24/ar-AA1lFMQL",
        "image": {
            "contentUrl": "https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1lFKZE.img?w=800&h=559&m=4&q=0",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.UptgcUn_1iiYYFgpGBVnAy&pid=News",
                "width": 700,
                "height": 489
            }
        },
        "description": "India's Vedanta Ltd on Monday declared a second interim dividend of 11 rupees per share for fiscal 2024, amounting to 40.89 billion rupees (about $492 million). The dividend comes at a time when UK-based parent Vedanta Resources grapples with multiple rating downgrades stemming from worries over outstanding dues,",
        "provider": [
            {
                "_type": "Organization",
                "name": "Reuters on MSN.com",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.jFXbg3L7Ce_1pS4_IOR8CA&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T11:48:57.0000000Z",
        "category": "Business"
    },
    {
        "name": "Sustainable Mailing And Shipping Trends In India, Oceania And Beyond",
        "url": "https://www.forbes.com/sites/forbesbusinesscouncil/2023/12/18/sustainable-mailing-and-shipping-trends-in-india-oceania-and-beyond/",
        "image": {
            "contentUrl": "https://imageio.forbes.com/specials-images/imageserve/657c6d4c4a5b1f0956868860/0x0.jpg?format=jpg&height=900&width=1600&fit=bounds",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.WEhyI0rdIAOgG2ZGNHMQpi&pid=News",
                "width": 700,
                "height": 433
            }
        },
        "description": "With the marked acceleration in ESG-related trends within the mailing and shipping sector, businesses can thrive by committing to sustainable practices.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/32d32c12-c315-7309-ef56-96f3e1d8d02d",
                "name": "Oceania"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/85fa63d3-9596-adb9-b4eb-502273d84f56",
                "name": "India"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/1670abaf-4212-e3f7-3b5d-00bb6b9b49b2",
                "name": "Mail"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "Forbes",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.U6nYa4Huf-RFEhjg-V4hNg&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T13:15:00.0000000Z"
    },
    {
        "name": "Goldman Ramps Up Credit Business in India, Targets Rich Diaspora",
        "url": "https://www.bloomberg.com/news/articles/2023-12-18/goldman-ramps-up-credit-business-in-india-targets-rich-diaspora",
        "image": {
            "contentUrl": "https://assets.bwbx.io/images/users/iqjWHBFdfxIU/isad4O1nkm.I/v0/1200x800.jpg",
            "thumbnail": {
                "contentUrl": "https://www.bing.com/th?id=OVFT.qTcjRUFJzet79eIglb3ShC&pid=News",
                "width": 700,
                "height": 466
            }
        },
        "description": "Goldman Sachs Group Inc. plans to ramp up its credit business in India and sees an increasing opportunity to target the nation’s wealthy diaspora as global investors shift their focus from China to what is now the world’s fastest-growing major economy.",
        "about": [
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/9cec1ad2-91b5-1585-e8b3-935fcd91d29e",
                "name": "Goldman Sachs"
            },
            {
                "readLink": "https://api.bing.microsoft.com/api/v7/entities/85fa63d3-9596-adb9-b4eb-502273d84f56",
                "name": "India"
            }
        ],
        "provider": [
            {
                "_type": "Organization",
                "name": "Bloomberg L.P.",
                "image": {
                    "thumbnail": {
                        "contentUrl": "https://www.bing.com/th?id=ODF.yAnAsiiCHte5V2il_I5mug&pid=news"
                    }
                }
            }
        ],
        "datePublished": "2023-12-18T04:45:00.0000000Z",
        "category": "Business"
    },
    
]




export const cachedNewsAggregatorHeadlines = {
    "articles": [
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "India Secures Series 2-1 Against South Africa",
            "description": "India vs South Africa Highlights, 3rd ODI: IND defeated SA by 78 runs to clinch the series 2-1, in Paarl on Thursday. Here are the highlights.",
            "url": "https://www.hindustantimes.com/cricket/india-vs-south-africa-live-score-3rd-odi-2023-today-21-december-ind-vs-sa-match-in-boland-park-paarl-101703142434003.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg",
            "publishedAt": "2023-12-21T18:52:08Z",
            "content": "India vs South Africa Highlights, 3rd ODI: India defeated South Africa by 78 runs in the third and final ODI of their three-match series, in Paarl on Thursday. The win helped India clinch the series … [+18237 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Chess.com"
            },
            "author": "Venkatachalam Saravanan",
            "title": "Gukesh Triumphs, Claims Giri's Candidates Spot",
            "description": "Gukesh won the title on tiebreak after tying for the first place with Arjun 4.5 points. Harikrishna and Eljanov finished in a tie for 3-4 places on four points.",
            "url": "https://www.chess.com/news/view/chennai-grand-masters-2023-round-7",
            "urlToImage": "https://images.chesscomfiles.com/uploads/v1/news/1323145.9ee5f824.5000x5000o.477cb814f52c.jpg",
            "publishedAt": "2023-12-21T16:07:00Z",
            "content": "GM Gukesh Dommaraju defended a slightly inferior endgame successfully against GM Pentala Harikrishna and won the title on tiebreak from GM Arjun Erigaisi after the seventh and final round of the Chen… [+8225 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Insidesport.in"
            },
            "author": "Krishnendu Banerjee",
            "title": "Gill, Jaiswal Impress in India-SA Practice Match",
            "description": "Shubman Gill & Yashasvi Jaiswal shine in intra-squad practice match to create selection dilemma for Rohit Sharma in IND vs SA Test.",
            "url": "https://www.insidesport.in/cricket/ind-vs-sa-test-shubman-gill-yashasvi-jaiswal-shine-in-intra-squad-practice-match-to-create-selection-dilemma/",
            "urlToImage": "https://d16f573ilcot6q.cloudfront.net/wp-content/uploads/2023/12/23-1.png",
            "publishedAt": "2023-12-21T14:45:09Z",
            "content": "Two youngsters Shubman Gill and Yashasvi Jaiswal still navigating through the red and whites of Test cricket have created a selection dilemma. With no tour game, the India Cricket Team began with an … [+1751 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricket.one"
            },
            "author": "Arpit Sachdeva",
            "title": "Omarzai to Replace Pandya in IPL 2024",
            "description": "We have compiled out best possible XI of Gujarat Titans for the upcoming season of the Indian Premier League. Let’s take a look at that:",
            "url": "https://cricket.one/cricket-analysis/omarzai-to-fill-hardik-pandya-void-in-shubman-gills-gt-best-playing-xi-for-ipl-2024/65844e40de38a068f4cd7958",
            "urlToImage": "https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703164899398_GBuQMB5a4AAUFm2.jpeg",
            "publishedAt": "2023-12-21T14:40:00Z",
            "content": "Gill will lead GT from IPL 2024 (x.com)\r\nGujarat Titans went at the IPL 2024 auction with the biggest purse of INR 38.15 crore. The departure of Hardik Pandya left them with a bid void to fill. They … [+3148 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "ESPN India"
            },
            "author": "ESPN",
            "title": "Man United, Bayern Firm on UEFA Commitment",
            "description": "Several of Europe's top clubs, including Manchester United and Bayern Munich, said on Thursday they remain committed to playing in UEFA competitions following the proposal of a new 64-team European club competition by Super League's backers, A22 Sports.",
            "url": "https://www.espn.in/football/story/_/id/39160537/man-united-bayern-commit-uefa-amid-super-league-verdict",
            "urlToImage": "https://a3.espncdn.com/combiner/i?img=%2Fphoto%2F2020%2F0427%2Fr693290_1296x729_16%2D9.jpg",
            "publishedAt": "2023-12-21T13:38:56Z",
            "content": "Several of Europe's top clubs, including Manchester United, Manchester City and Bayern Munich, said on Thursday they remain committed to playing in competitions run by UEFA following the proposal of … [+3721 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "Khawaja Reprimanded for Message in AUS-PAK Test",
            "description": "Australia opener Usman Khawaja has been reprimanded by the ICC for ‘displaying a personal message’ in the Perth Test. | Cricket",
            "url": "https://www.hindustantimes.com/cricket/icc-punishes-usman-khawaja-for-displaying-personal-message-in-australia-vs-pakistan-test-at-perth-101703165174276.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/CRICKET-AUS-PAK-TEST-0_1703165555246_1703165610504.jpg",
            "publishedAt": "2023-12-21T13:34:36Z",
            "content": "Australian opener Usman Khawaja is facing charges from the International Cricket Council (ICC) for a form of protest involving the donning of a black armband during the Test match against Pakistan at… [+3333 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Crictoday.com"
            },
            "author": "Venkat Narayanan",
            "title": "Dhoni's Response to RCB Switch Request Goes Viral",
            "description": "In a viral social media moment, a fan requested MS Dhoni to leave Chennai Super Kings (CSK) and join Royal Challengers Bangalore (RCB).",
            "url": "http://crictoday.com/cricket/daily-cricket-news/fan-asks-ms-dhoni-to-switch-sides-from-csk-to-rcb-and-the-iconic-captains-response-breaks-the-internet/",
            "urlToImage": "https://crictoday.com/wp-content/uploads/2023/03/ms-dhoni-cricket-today-12.webp",
            "publishedAt": "2023-12-21T13:30:35Z",
            "content": "CSK legend MS Dhoni recently garnered attention for his graceful response to a fan’s intriguing request during an interaction.\r\nSince the inception of the IPL in 2008, Dhoni has led CSK to an impress… [+1269 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Renin Wilben Albert",
            "title": "De Villiers Doubts KKR's IPL 2024 Chances",
            "description": "Former South African batter AB de Villiers reckons that Kolkata Knight Riders (KKR) do not have a strong enough batting line-up to win the Indian Premier League (IPL) 2024.",
            "url": "https://www.sportskeeda.com/cricket/news-i-don-t-think-that-s-ipl-winning-batting-line-up-ab-de-villiers-makes-big-statement-one-top-franchises-ahead-2024-season",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/712c6-17031649830534-1920.jpg",
            "publishedAt": "2023-12-21T13:29:08Z",
            "content": "Former South African batter AB de Villiers reckons that Kolkata Knight Riders (KKR) do not have a strong enough batting line-up to win the Indian Premier League (IPL) 2024. He singled out Shreyas Iye… [+2168 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "YouTube"
            },
            "author": null,
            "title": "Sakshi Malik Announces Wrestling Retirement",
            "description": "Sakshi Malik Retirement: Following the election of Sanjay Singh as president of the Wrestling Federation of India (WFI), 2016 Rio Olympics bronze medallist S...",
            "url": "https://www.youtube.com/watch?v=8IOmIAk-c7o",
            "urlToImage": "https://i.ytimg.com/vi/8IOmIAk-c7o/maxresdefault.jpg",
            "publishedAt": "2023-12-21T13:01:30Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "YouTube"
            },
            "author": null,
            "title": "Mitchell Starc becomes most expensive buy in IPL history! Cricbuzz Live reacts - Cricbuzz",
            "description": "Kolkata Knight Riders splurge 24.75 cr. for Mitchell Starc at the IPL 2024 Mini Auction. Harsha Bhogle & Joy Bhattacharjya make sense of the move, on Cricbuz...",
            "url": "https://www.youtube.com/watch?v=Vj7dqjglmqM",
            "urlToImage": "https://i.ytimg.com/vi/Vj7dqjglmqM/maxresdefault.jpg",
            "publishedAt": "2023-12-21T12:30:13Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "Cricketaddictor.com"
            },
            "author": "Vicky Singh",
            "title": "Mitchell Starc's Record IPL 2024 Auction Bid",
            "description": "BAW vs CRD Dream11 Prediction, Fantasy Cricket Tips, Dream11 Team, Playing XI, Pitch Report, Injury Update of Dream11 Nature Isle T10 2023 Qualifier 1 match between Barana Aute Warriors and Champagne Reef Divers.",
            "url": "https://cricketaddictor.com/fantasy-cricket/dream11/baw-vs-crd-dream11-prediction-today-match-qualifier-1-dream11-nature-isle-t10-2023/",
            "urlToImage": "https://cricketaddictor.com/wp-content/uploads/2022/06/The-Village-Ground.jpg",
            "publishedAt": "2023-12-21T12:08:38Z",
            "content": "BAW vs CRD Dream11 Prediction, Fantasy Cricket Tips, Dream11 Team, Playing XI, Pitch Report, Injury Update of Dream11 Nature Isle T10 2023 Qualifier 1 match between Barana Aute Warriors and Champagne… [+6128 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "Watch: Tom Curran, RCB's latest buy, handed 4-match ban for intimidating umpire - Hindustan Times",
            "description": "Tom Curran was handed a four-match ban for intimidating an umpire before Sydney Sixers' BBL match against Hobart Hurricanes. | Cricket",
            "url": "https://www.hindustantimes.com/cricket/watch-tom-curran-rcbs-newest-recruit-slapped-with-4-match-ban-for-intimidating-umpire-with-near-collision-in-bbl-101703158890352.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/tom_curran1_1703160381013_1703160389822.png",
            "publishedAt": "2023-12-21T12:08:23Z",
            "content": "Sydney Sixers' versatile player, Tom Curran, faces a significant setback as he received a four-match ban for engaging in an altercation with an umpire before a Big Bash League (BBL) game. The 28-year… [+2408 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricket.one"
            },
            "author": "Arpit Sachdeva",
            "title": "Daryl Mitchell Joins CSK for IPL 2024",
            "description": "In this article, we have tried to compile the best playing XI of CSK for the upcoming season of IPL.",
            "url": "https://cricket.one/cricket-analysis/daryl-mitchell-slotted-in-for-thalas-last-dance-csks-best-playing-xi-for-ipl-2024/6584239dadb0e87443bb1a39",
            "urlToImage": "https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703158664218_GByxluKXwAAwslW.jpeg",
            "publishedAt": "2023-12-21T11:38:05Z",
            "content": "MS Dhoni might have a last dance in IPL 2024 (x.com)\r\nGoing into the Indian Premier League (IPL) 2024 auction, Chennai Super Kings had INR 31.4 crores in their purse with six slots to be filled. They… [+2886 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "BCCI Updates on Gaikwad Injury; Patidar Debuts",
            "description": "BCCI provided a crucial injury update on Ruturaj Gaikwad. Rajat Patidar made his ODI debut against South Africa in the 3rd ODI.  | Cricket",
            "url": "https://www.hindustantimes.com/cricket/bcci-provides-crucial-injury-update-on-ruturaj-gaikwad-rajat-patidar-makes-odi-debut-against-south-africa-in-decider-101703156022763.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/AP12-19-2023-000218B-0_1703158552670_1703158576661.jpg",
            "publishedAt": "2023-12-21T11:37:12Z",
            "content": "Speed merchant Mukesh Kumar almost provided KL Rahul's Team India with an early breakthrough when his angling delivery into off-stump outsmarted Reeza Hendricks. The delivery created a catching oppor… [+2658 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Aditya Suketu Desai",
            "title": "Fans Speculate on Chahal's International Career",
            "description": "Senior leg-spinner Yuzvendra Chahal did not find a place in India's playing XI for the third and final ODI against South Africa at Boland Park in Paarl on Thursday, December 21.",
            "url": "https://www.sportskeeda.com/cricket/news-his-international-career-over-fans-react-india-bench-yuzvendra-chahal-3rd-odi-vs-south-africa",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/85857-17031569094556-1920.jpg",
            "publishedAt": "2023-12-21T11:22:32Z",
            "content": "Senior leg-spinner Yuzvendra Chahal did not find a place in India's playing XI for the third and final ODI against South Africa at Boland Park in Paarl on Thursday, December 21.\r\nChahal returned to I… [+1823 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Renin Wilben Albert",
            "title": "AB de Villiers: SRH Could Win IPL",
            "description": "Former South African batter AB de Villiers reckons that SunRisers Hyderabad (SRH) have put together a squad that can go on to win the Indian Premier League (IPL) in the upcoming season.",
            "url": "https://www.sportskeeda.com/cricket/news-that-s-team-can-win-ipl-ab-de-villiers-reckons-underperforming-franchise-one-strongest-squads-upcoming-season",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/5f717-17031565152026-1920.jpg",
            "publishedAt": "2023-12-21T11:08:41Z",
            "content": "Former South African batter AB de Villiers reckons that SunRisers Hyderabad (SRH) have put together a squad that can go on to win the Indian Premier League (IPL) in the upcoming season. De Villiers o… [+2074 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Crictoday.com"
            },
            "author": "Venkat Narayanan",
            "title": "Mohammed Siraj's Cryptic Message Causes Stir",
            "description": "Indian cricket team fast bowler Mohammed Siraj has set tongues wagging with a cryptic message posted on his Instagram story",
            "url": "http://crictoday.com/cricket/news/all-is-not-well-in-team-india-mohammed-sirajs-cryptic-message-sparks-speculation/",
            "urlToImage": "https://crictoday.com/wp-content/uploads/2023/12/ezgif.com-jpg-to-webp-converted-22.webp",
            "publishedAt": "2023-12-21T10:59:28Z",
            "content": "One after another, Indian cricketers are posting cryptic messages on Instagram. Mohammed Siraj is the latest to join the trend. On Thursday, the Indian pacer posted a story featuring five broken hear… [+2114 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "YouTube"
            },
            "author": null,
            "title": "Top Goals: Barcelona vs. Mexican Teams",
            "description": "FC Barcelona has travelled to Texas for the last game of 2023. After beating Almería 3-2, they headed straight to the airport to fly to Dallas and a game wit...",
            "url": "https://www.youtube.com/watch?v=bD4EbcEfr2A",
            "urlToImage": "https://i.ytimg.com/vi/bD4EbcEfr2A/maxresdefault.jpg",
            "publishedAt": "2023-12-21T10:29:48Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Renin Wilben Albert",
            "title": "de Villiers on Curran's IPL Overpayment",
            "description": "Former South African captain AB de Villiers reckons that England all-rounder Sam Curran has been overpaid in the Indian Premier League (IPL) for quite a few years now.",
            "url": "https://www.sportskeeda.com/cricket/news-he-s-overpaid-quite-years-now-ab-de-villiers-blunt-assessment-pbks-star-oversees-player",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/b16f0-17031534892128-1920.jpg",
            "publishedAt": "2023-12-21T10:17:05Z",
            "content": "Former South African captain AB de Villiers reckons that England all-rounder Sam Curran has been overpaid in the Indian Premier League (IPL) for quite a few years now. While agreeing that the versati… [+2576 chars]"
        },

        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "India Wins Series Against South Africa",
            "description": "India vs South Africa Highlights, 3rd ODI: IND defeated SA by 78 runs to clinch the series 2-1, in Paarl on Thursday. Here are the highlights.",
            "url": "https://www.hindustantimes.com/cricket/india-vs-south-africa-live-score-3rd-odi-2023-today-21-december-ind-vs-sa-match-in-boland-park-paarl-101703142434003.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg",
            "publishedAt": "2023-12-21T18:52:08Z",
            "content": "India vs South Africa Highlights, 3rd ODI: India defeated South Africa by 78 runs in the third and final ODI of their three-match series, in Paarl on Thursday. The win helped India clinch the series … [+18237 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Chess.com"
            },
            "author": "Venkatachalam Saravanan",
            "title": "Gukesh Wins Chennai Grand Masters",
            "description": "Gukesh won the title on tiebreak after tying for the first place with Arjun 4.5 points. Harikrishna and Eljanov finished in a tie for 3-4 places on four points.",
            "url": "https://www.chess.com/news/view/chennai-grand-masters-2023-round-7",
            "urlToImage": "https://images.chesscomfiles.com/uploads/v1/news/1323145.9ee5f824.5000x5000o.477cb814f52c.jpg",
            "publishedAt": "2023-12-21T16:07:00Z",
            "content": "GM Gukesh Dommaraju defended a slightly inferior endgame successfully against GM Pentala Harikrishna and won the title on tiebreak from GM Arjun Erigaisi after the seventh and final round of the Chen… [+8225 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Insidesport.in"
            },
            "author": "Krishnendu Banerjee",
            "title": "Gill, Jaiswal Impress in Intra-Squad Match",
            "description": "Shubman Gill & Yashasvi Jaiswal shine in intra-squad practice match to create selection dilemma for Rohit Sharma in IND vs SA Test.",
            "url": "https://www.insidesport.in/cricket/ind-vs-sa-test-shubman-gill-yashasvi-jaiswal-shine-in-intra-squad-practice-match-to-create-selection-dilemma/",
            "urlToImage": "https://d16f573ilcot6q.cloudfront.net/wp-content/uploads/2023/12/23-1.png",
            "publishedAt": "2023-12-21T14:45:09Z",
            "content": "Two youngsters Shubman Gill and Yashasvi Jaiswal still navigating through the red and whites of Test cricket have created a selection dilemma. With no tour game, the India Cricket Team began with an … [+1751 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricket.one"
            },
            "author": "Arpit Sachdeva",
            "title": "Omarzai Replaces Pandya in GT for IPL",
            "description": "We have compiled out best possible XI of Gujarat Titans for the upcoming season of the Indian Premier League. Let’s take a look at that:",
            "url": "https://cricket.one/cricket-analysis/omarzai-to-fill-hardik-pandya-void-in-shubman-gills-gt-best-playing-xi-for-ipl-2024/65844e40de38a068f4cd7958",
            "urlToImage": "https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703164899398_GBuQMB5a4AAUFm2.jpeg",
            "publishedAt": "2023-12-21T14:40:00Z",
            "content": "Gill will lead GT from IPL 2024 (x.com)\r\nGujarat Titans went at the IPL 2024 auction with the biggest purse of INR 38.15 crore. The departure of Hardik Pandya left them with a bid void to fill. They … [+3148 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "ESPN India"
            },
            "author": "ESPN",
            "title": "Man United, Bayern Commit to UEFA",
            "description": "Several of Europe's top clubs, including Manchester United and Bayern Munich, said on Thursday they remain committed to playing in UEFA competitions following the proposal of a new 64-team European club competition by Super League's backers, A22 Sports.",
            "url": "https://www.espn.in/football/story/_/id/39160537/man-united-bayern-commit-uefa-amid-super-league-verdict",
            "urlToImage": "https://a3.espncdn.com/combiner/i?img=%2Fphoto%2F2020%2F0427%2Fr693290_1296x729_16%2D9.jpg",
            "publishedAt": "2023-12-21T13:38:56Z",
            "content": "Several of Europe's top clubs, including Manchester United, Manchester City and Bayern Munich, said on Thursday they remain committed to playing in competitions run by UEFA following the proposal of … [+3721 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "ICC Penalizes Khawaja for Personal Message",
            "description": "Australia opener Usman Khawaja has been reprimanded by the ICC for ‘displaying a personal message’ in the Perth Test. | Cricket",
            "url": "https://www.hindustantimes.com/cricket/icc-punishes-usman-khawaja-for-displaying-personal-message-in-australia-vs-pakistan-test-at-perth-101703165174276.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/CRICKET-AUS-PAK-TEST-0_1703165555246_1703165610504.jpg",
            "publishedAt": "2023-12-21T13:34:36Z",
            "content": "Australian opener Usman Khawaja is facing charges from the International Cricket Council (ICC) for a form of protest involving the donning of a black armband during the Test match against Pakistan at… [+3333 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Crictoday.com"
            },
            "author": "Venkat Narayanan",
            "title": "Dhoni's Response to Fan Goes Viral",
            "description": "In a viral social media moment, a fan requested MS Dhoni to leave Chennai Super Kings (CSK) and join Royal Challengers Bangalore (RCB).",
            "url": "http://crictoday.com/cricket/daily-cricket-news/fan-asks-ms-dhoni-to-switch-sides-from-csk-to-rcb-and-the-iconic-captains-response-breaks-the-internet/",
            "urlToImage": "https://crictoday.com/wp-content/uploads/2023/03/ms-dhoni-cricket-today-12.webp",
            "publishedAt": "2023-12-21T13:30:35Z",
            "content": "CSK legend MS Dhoni recently garnered attention for his graceful response to a fan’s intriguing request during an interaction.\r\nSince the inception of the IPL in 2008, Dhoni has led CSK to an impress… [+1269 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Renin Wilben Albert",
            "title": "de Villiers Doubtful of KKR's IPL Chances",
            "description": "Former South African batter AB de Villiers reckons that Kolkata Knight Riders (KKR) do not have a strong enough batting line-up to win the Indian Premier League (IPL) 2024.",
            "url": "https://www.sportskeeda.com/cricket/news-i-don-t-think-that-s-ipl-winning-batting-line-up-ab-de-villiers-makes-big-statement-one-top-franchises-ahead-2024-season",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/712c6-17031649830534-1920.jpg",
            "publishedAt": "2023-12-21T13:29:08Z",
            "content": "Former South African batter AB de Villiers reckons that Kolkata Knight Riders (KKR) do not have a strong enough batting line-up to win the Indian Premier League (IPL) 2024. He singled out Shreyas Iye… [+2168 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "YouTube"
            },
            "author": null,
            "title": "Sakshi Malik Announces Wrestling Retirement",
            "description": "Sakshi Malik Retirement: Following the election of Sanjay Singh as president of the Wrestling Federation of India (WFI), 2016 Rio Olympics bronze medallist S...",
            "url": "https://www.youtube.com/watch?v=8IOmIAk-c7o",
            "urlToImage": "https://i.ytimg.com/vi/8IOmIAk-c7o/maxresdefault.jpg",
            "publishedAt": "2023-12-21T13:01:30Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "YouTube"
            },
            "author": null,
            "title": "Mitchell Starc: Most Expensive IPL Buy",
            "description": "Kolkata Knight Riders splurge 24.75 cr. for Mitchell Starc at the IPL 2024 Mini Auction. Harsha Bhogle & Joy Bhattacharjya make sense of the move, on Cricbuz...",
            "url": "https://www.youtube.com/watch?v=Vj7dqjglmqM",
            "urlToImage": "https://i.ytimg.com/vi/Vj7dqjglmqM/maxresdefault.jpg",
            "publishedAt": "2023-12-21T12:30:13Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "Cricketaddictor.com"
            },
            "author": "Vicky Singh",
            "title": "BAW vs CRD: Nature Isle T10 Match Prediction",
            "description": "BAW vs CRD Dream11 Prediction, Fantasy Cricket Tips, Dream11 Team, Playing XI, Pitch Report, Injury Update of Dream11 Nature Isle T10 2023 Qualifier 1 match between Barana Aute Warriors and Champagne Reef Divers.",
            "url": "https://cricketaddictor.com/fantasy-cricket/dream11/baw-vs-crd-dream11-prediction-today-match-qualifier-1-dream11-nature-isle-t10-2023/",
            "urlToImage": "https://cricketaddictor.com/wp-content/uploads/2022/06/The-Village-Ground.jpg",
            "publishedAt": "2023-12-21T12:08:38Z",
            "content": "BAW vs CRD Dream11 Prediction, Fantasy Cricket Tips, Dream11 Team, Playing XI, Pitch Report, Injury Update of Dream11 Nature Isle T10 2023 Qualifier 1 match between Barana Aute Warriors and Champagne… [+6128 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "Tom Curran Suspended for Intimidating Umpire",
            "description": "Tom Curran was handed a four-match ban for intimidating an umpire before Sydney Sixers' BBL match against Hobart Hurricanes. | Cricket",
            "url": "https://www.hindustantimes.com/cricket/watch-tom-curran-rcbs-newest-recruit-slapped-with-4-match-ban-for-intimidating-umpire-with-near-collision-in-bbl-101703158890352.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/tom_curran1_1703160381013_1703160389822.png",
            "publishedAt": "2023-12-21T12:08:23Z",
            "content": "Sydney Sixers' versatile player, Tom Curran, faces a significant setback as he received a four-match ban for engaging in an altercation with an umpire before a Big Bash League (BBL) game. The 28-year… [+2408 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricket.one"
            },
            "author": "Arpit Sachdeva",
            "title": "Mitchell Replaces Dhoni in CSK's IPL Lineup",
            "description": "In this article, we have tried to compile the best playing XI of CSK for the upcoming season of IPL.",
            "url": "https://cricket.one/cricket-analysis/daryl-mitchell-slotted-in-for-thalas-last-dance-csks-best-playing-xi-for-ipl-2024/6584239dadb0e87443bb1a39",
            "urlToImage": "https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703158664218_GByxluKXwAAwslW.jpeg",
            "publishedAt": "2023-12-21T11:38:05Z",
            "content": "MS Dhoni might have a last dance in IPL 2024 (x.com)\r\nGoing into the Indian Premier League (IPL) 2024 auction, Chennai Super Kings had INR 31.4 crores in their purse with six slots to be filled. They… [+2886 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "India Benches Chahal in ODI vs South Africa",
            "description": "BCCI provided a crucial injury update on Ruturaj Gaikwad. Rajat Patidar made his ODI debut against South Africa in the 3rd ODI.  | Cricket",
            "url": "https://www.hindustantimes.com/cricket/bcci-provides-crucial-injury-update-on-ruturaj-gaikwad-rajat-patidar-makes-odi-debut-against-south-africa-in-decider-101703156022763.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/AP12-19-2023-000218B-0_1703158552670_1703158576661.jpg",
            "publishedAt": "2023-12-21T11:37:12Z",
            "content": "Speed merchant Mukesh Kumar almost provided KL Rahul's Team India with an early breakthrough when his angling delivery into off-stump outsmarted Reeza Hendricks. The delivery created a catching oppor… [+2658 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Aditya Suketu Desai",
            "title": "India Benches Chahal in ODI vs South Africa",
            "description": "Senior leg-spinner Yuzvendra Chahal did not find a place in India's playing XI for the third and final ODI against South Africa at Boland Park in Paarl on Thursday, December 21.",
            "url": "https://www.sportskeeda.com/cricket/news-his-international-career-over-fans-react-india-bench-yuzvendra-chahal-3rd-odi-vs-south-africa",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/85857-17031569094556-1920.jpg",
            "publishedAt": "2023-12-21T11:22:32Z",
            "content": "Senior leg-spinner Yuzvendra Chahal did not find a place in India's playing XI for the third and final ODI against South Africa at Boland Park in Paarl on Thursday, December 21.\r\nChahal returned to I… [+1823 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Renin Wilben Albert",
            "title": "“That's a team that can win the IPL” - AB de Villiers reckons underperforming franchise has one of the strongest squads for upcoming season - Sportskeeda",
            "description": "Former South African batter AB de Villiers reckons that SunRisers Hyderabad (SRH) have put together a squad that can go on to win the Indian Premier League (IPL) in the upcoming season.",
            "url": "https://www.sportskeeda.com/cricket/news-that-s-team-can-win-ipl-ab-de-villiers-reckons-underperforming-franchise-one-strongest-squads-upcoming-season",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/5f717-17031565152026-1920.jpg",
            "publishedAt": "2023-12-21T11:08:41Z",
            "content": "Former South African batter AB de Villiers reckons that SunRisers Hyderabad (SRH) have put together a squad that can go on to win the Indian Premier League (IPL) in the upcoming season. De Villiers o… [+2074 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Crictoday.com"
            },
            "author": "Venkat Narayanan",
            "title": "Siraj's Cryptic Post Sparks Speculation",
            "description": "Indian cricket team fast bowler Mohammed Siraj has set tongues wagging with a cryptic message posted on his Instagram story",
            "url": "http://crictoday.com/cricket/news/all-is-not-well-in-team-india-mohammed-sirajs-cryptic-message-sparks-speculation/",
            "urlToImage": "https://crictoday.com/wp-content/uploads/2023/12/ezgif.com-jpg-to-webp-converted-22.webp",
            "publishedAt": "2023-12-21T10:59:28Z",
            "content": "One after another, Indian cricketers are posting cryptic messages on Instagram. Mohammed Siraj is the latest to join the trend. On Thursday, the Indian pacer posted a story featuring five broken hear… [+2114 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "YouTube"
            },
            "author": null,
            "title": "FC Barcelona's Top Goals Against Mexican Teams",
            "description": "FC Barcelona has travelled to Texas for the last game of 2023. After beating Almería 3-2, they headed straight to the airport to fly to Dallas and a game wit...",
            "url": "https://www.youtube.com/watch?v=bD4EbcEfr2A",
            "urlToImage": "https://i.ytimg.com/vi/bD4EbcEfr2A/maxresdefault.jpg",
            "publishedAt": "2023-12-21T10:29:48Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Renin Wilben Albert",
            "title": "de Villiers Criticizes Curran's IPL Salary",
            "description": "Former South African captain AB de Villiers reckons that England all-rounder Sam Curran has been overpaid in the Indian Premier League (IPL) for quite a few years now.",
            "url": "https://www.sportskeeda.com/cricket/news-he-s-overpaid-quite-years-now-ab-de-villiers-blunt-assessment-pbks-star-oversees-player",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/b16f0-17031534892128-1920.jpg",
            "publishedAt": "2023-12-21T10:17:05Z",
            "content": "Former South African captain AB de Villiers reckons that England all-rounder Sam Curran has been overpaid in the Indian Premier League (IPL) for quite a few years now. While agreeing that the versati… [+2576 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sydneysixers.com.au"
            },
            "author": "sydney-sixers",
            "title": "Sydney Sixers Confirm Squad vs Strikers",
            "description": "Enjoy the fun and excitement of the KFC T20 Big Bash League with international stars playing alongside Australia’s best home grown talent.",
            "url": "https://www.sydneysixers.com.au/news/3830710/sixers-squad-confirmed-for-clash-with-strikers",
            "urlToImage": "https://resources.sydney-sixers.pulselive.com/sydney-sixers/photo/2023/12/21/e6dc8b84-396d-48e5-9bed-379a82155291/GettyImages-1846874013.jpg",
            "publishedAt": "2023-12-21T09:37:50Z",
            "content": "The Sydney Sixers have named a 17-player squad for their Friday night clash with the Adelaide Strikers at the SCG.\r\nBeginning their season with back-to-back wins, the Sixers currently sit third on th… [+1304 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricket.one"
            },
            "author": "Debashis Sarangi",
            "title": "Pandya to Lead MI; Bumrah-Coetzee Pairing",
            "description": "Regarding Mumbai Indians, the five-time champions entered the auction with a budget of INR 17.75 Crores, which they used brilliantly to buy ace South African speedster Gerald Coetzee besides the Lankan duo of Dilshan Madushanka and Nuwan Thushara.",
            "url": "https://cricket.one/cricket-analysis/pandya-to-lead-coetzee-bumrah-to-bowl-in-tandem-heres-mis-best-playing-xi-for-ipl-2024/65840340de38a068f4cd783c",
            "urlToImage": "https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703145738457_1703127891054_GBuZe_VWAAAuaOG.jpeg",
            "publishedAt": "2023-12-21T09:20:00Z",
            "content": "Hardik Pandya will lead MI in IPL 2024 [X.com]\r\nThe much-anticipated IPL 2024 auction concluded on Tuesday, with the ten franchises finalising their squad for the tournament's upcoming season. Regard… [+2409 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "‘Cummins a Test bowler’: Gillespie",
            "description": "Gillespie was ecstatic for both Starc and Cummins but he didn't quite agree with the amount paid to the Australian captain. | Cricket",
            "url": "https://www.hindustantimes.com/cricket/cummins-a-test-bowler-gillespie-raises-questions-on-srhs-rs-20-5-cr-buy-no-issues-with-starc-getting-rs-24-75-cr-101703147342099.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/India-Cricket-WCup-261_1700297096741_1703147546628.jpg",
            "publishedAt": "2023-12-21T08:42:08Z",
            "content": "Mitchell Starc and Pat Cummins rewrote the record books after Kolkata Knight Riders and Sunrisers Hyderabad shelled eye-popping amounts to acquire their services for IPL 2024. Starc, bought for 24.75… [+2509 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Crictoday.com"
            },
            "author": "CT Contributor",
            "title": "Gillespie Questions Cummins' IPL Worth",
            "description": "Former South African captain AB de Villiers expressed his desire to see Rashid Khan playing for Royal Challengers Bangalore.",
            "url": "http://crictoday.com/cricket/daily-cricket-news/rcb-should-trade-rashid-khan-from-gujarat-titans-ab-de-villiers/",
            "urlToImage": "https://crictoday.com/wp-content/uploads/2023/12/Rashid-Khan.webp",
            "publishedAt": "2023-12-21T07:35:58Z",
            "content": "AB de Villiers expressed his desire to see Rashid Khan playing for Royal Challengers Bangalore. However, he knows that it is impossible at the moment, considering his association with Gujarat Titans … [+1032 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "de Villiers Suggests Rashid Khan for RCB",
            "description": "Rohit Sharma will not be leading the Mumbai Indians in the 2024 season, with Hardik Pandya being named franchise's new captain earlier this month. | Cricket",
            "url": "https://www.hindustantimes.com/cricket/rohit-sharma-for-me-is-a-question-mark-in-t20-manjrekars-razor-sharp-remark-after-hardiks-ascension-as-mi-captain-101703094957580.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/20/1600x900/PTI03-27-2022-000106B-0_1648378206095_1703097251012.jpg",
            "publishedAt": "2023-12-21T05:05:02Z",
            "content": "The Mumbai Indians, gearing up for the 2024 IPL season, made a significant move earlier this month as they appointed Hardik Pandya as their new captain. Hardik returned to the Mumbai Indians after tw… [+2645 chars]"
        },
        {
            "source": {
                "id": "espn-cric-info",
                "name": "ESPN Cric Info"
            },
            "author": "ESPNcricinfo staff",
            "title": "Manjrekar on Rohit's T20 Capability",
            "description": "Bosch, Wolvaardt and Brits hit half-centuries as the hosts eased their way through a chase of 223",
            "url": "https://www.espncricinfo.com/series/bangladesh-women-in-south-africa-2023-24-1398249/south-africa-women-vs-bangladesh-women-2nd-odi-1398259/match-report",
            "urlToImage": "https://img1.hscicdn.com/image/upload/f_auto/lsci/db/PICTURES/CMS/372200/372221.6.jpg",
            "publishedAt": "2023-12-21T05:01:37Z",
            "content": "ReportBosch, Wolvaardt and Brits hit half-centuries as the hosts eased their way through a chase of 223"
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Renin Wilben Albert",
            "title": "Bosch, Wolvaardt Star in SA vs BDESH ODI",
            "description": "Team India head coach Rahul Dravid’s son Samit starred with 98 off 159 balls while representing Karnataka against Jammu & Kashmir in a Cooch Behar Trophy (U19) match in Jammu recently.",
            "url": "https://www.sportskeeda.com/cricket/news-watch-rahul-dravid-s-son-samit-plays-attractive-strokes-knock-98-cooch-behar-trophy",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/73573-17031332239414-1920.jpg",
            "publishedAt": "2023-12-21T04:37:24Z",
            "content": "Team India head coachRahul Dravids son Samit starred with 98 off 159 balls while representing Karnataka against Jammu &amp; Kashmir in a Cooch Behar Trophy (U19) match in Jammu recently. A video feat… [+1769 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricket.one"
            },
            "author": "Sai",
            "title": "Samit Dravid Shines in Cooch Behar Trophy",
            "description": "Aaron Hardie & Zak Crawley, the dynamic duo from the Perth Scorchers, orchestrated a magnificent unbeaten partnership of 157 runs, steering their side to a commanding nine-wicket victory.",
            "url": "https://cricket.one/cricket-analysis/bbl-2023-24-sco-vs-hur-impact-performer-aaron-hardie/6583be7372374f76ad1ea905",
            "urlToImage": "https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703132779494_Hardie111.jpeg",
            "publishedAt": "2023-12-21T04:26:27Z",
            "content": "Aaron Hardie guided the Scorchers to a victory against the Hobart Hurricanes (Source: x.com)\r\nPerth Scorchers asserted their dominance with a commanding win, marking their second consecutive triumph … [+3563 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "India.com"
            },
            "author": "Shubham Pandey",
            "title": "Aaron Hardie: Impact Performer in BBL",
            "description": "Really Excited To Play Under Stephen Fleming, Daryl Mitchell Delighted To Join MS Dhonis Chennai Super Kings Salt has played IPL for Delhi Capitals who had picked him for a sum of Rs 2 crore in last years auction. But DC released him ahead of IPL 2024 Auction…",
            "url": "https://zeenews.india.com/cricket/ipl-2024-auction-england-batter-phil-salt-shocked-after-all-10-franchises-refuse-to-buy-him-2701304.html",
            "urlToImage": "https://english.cdn.zeenews.com/sites/default/files/2023/12/21/1339537-phil-salt.png",
            "publishedAt": "2023-12-21T04:20:00Z",
            "content": "While it is true that in smaller Indian Premier League (IPL) auctions, foreign players receive massive money by the franchises, there are some overseas cricketers who are left confused on why no one … [+1672 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sportskeeda"
            },
            "author": "Renin Wilben Albert",
            "title": "Ponting on Delhi Capitals' IPL Auction Strategy",
            "description": "Delhi Capitals’ (DC) head coach Ricky Ponting has revealed that purchasing hard-hitting England batter Harry Brook was their number one priority at the Indian Premier League (IPL) 2024 auction in Duba",
            "url": "https://www.sportskeeda.com/cricket/news-he-number-one-priority-ricky-ponting-opens-delhi-capitals-ipl-2024-auction-buys",
            "urlToImage": "https://staticg.sportskeeda.com/editor/2023/12/b843a-17031305129229-1920.jpg",
            "publishedAt": "2023-12-21T03:54:10Z",
            "content": "Delhi Capitals (DC) head coach Ricky Ponting has revealed that purchasing hard-hitting England batter Harry Brook was their number one priority at the Indian Premier League (IPL) 2024 auction in Duba… [+2181 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "BBC News"
            },
            "author": null,
            "title": "Nehra Discusses Hardik Pandya's GT Exit",
            "description": "The former India pacer said the franchise will back Shubman Gill irrespective of the results in the next edition of the IPL.",
            "url": "http://www.bbc.com/news/world-us-canada-39324587",
            "urlToImage": "https://c.ndtvimg.com/2023-12/gf7u4kkg_hardik-pandya-and-ashish-nehra-bcci_625x300_20_December_23.jpg?im=FeatureCrop,algorithm=dnn,width=1200,height=675",
            "publishedAt": "2023-12-21T02:56:43Z",
            "content": "Gujarat Titans head coach Ashish Nehra admitted that it will be tough to replace a multi-faceted player like Hardik Pandya but banked on Shubman Gill to shoulder the responsibility of leading the sid… [+3082 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Crictoday.com"
            },
            "author": "CT Contributor",
            "title": "Shazad Out of Pakistan vs Australia Tests",
            "description": "Khurram Shazad has been ruled out of the remaining games due to a stress fracture in his left rib and an abdominal muscle tear.",
            "url": "http://crictoday.com/cricket/daily-cricket-news/pakistan-suffer-a-big-jolt-as-star-player-ruled-out-of-remaining-test-matches-against-australia/",
            "urlToImage": "https://crictoday.com/wp-content/uploads/2023/12/Khurram.webp",
            "publishedAt": "2023-12-21T02:55:18Z",
            "content": "Pakistan have suffered a big jolt before the Boxing Day Test against Australia on December 26 at the Melbourne Cricket Ground. Khurram Shazad has been ruled out of the remaining games due to a stress… [+839 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "The Cricket Times"
            },
            "author": "Akshat Gaur",
            "title": "India's Series Win Over South Africa",
            "description": "South Africa vs India Dream11 Team for today's match - December 21, 11:00 am GMT | India tour of South Africa 2023-24. The Boland Park in Paarl will host the contest.",
            "url": "https://crickettimes.com/2023/12/sa-vs-ind-3rd-odi-match-prediction-dream11-team-fantasy-tips-pitch-report-south-africa-vs-india/",
            "urlToImage": "https://crickettimes.com/wp-content/uploads/2023/12/SA-vs-IND-Dream11-Prediction-3rd-ODI.webp",
            "publishedAt": "2023-12-21T02:49:04Z",
            "content": "In a thrilling culmination to the three-match One Day International (ODI) series, South Africa are gearing up to face India in the decisive third ODI at Boland Park in Paarl on Thursday, December 21.… [+2420 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "Gavaskar's verdict on Sai Sudharsan..",
            "description": "Batting legend Sunil Gavaskar revealed his early impressions of India's rising star ahead of the 3rd ODI against South Africa. | Cricket",
            "url": "https://www.hindustantimes.com/cricket/sunil-gavaskar-verdict-on-sai-sudharsan-india-vs-south-africa-3rd-odi-you-dont-want-to-be-getting-out-ind-vs-sa-101703090053110.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/20/1600x900/PTI11-19-2023-000153B-0_1703094998448_1703095030546.jpg",
            "publishedAt": "2023-12-21T01:02:04Z",
            "content": "India were blown away by the Tony de Zorzi storm as South Africa restored parity in the series with an impressive win over KL Rahul and Co. in the 2nd One Day International (ODI) on Tuesday. Sai Sudh… [+2869 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Crictoday.com"
            },
            "author": "Venkat Narayanan",
            "title": "Biggest blunder in IPL auction history.. ",
            "description": "Punjab Kings clarified the confusion surrounding the IPL 2024 auction, where both players No. 236 and 237 included Shashank Singh",
            "url": "http://crictoday.com/cricket/daily-cricket-news/punjab-kings-issues-clarification-on-biggest-blunder-in-ipl-auction-history/",
            "urlToImage": "https://crictoday.com/wp-content/uploads/2023/12/ezgif.com-jpg-to-webp-converted-86.webp",
            "publishedAt": "2023-12-21T00:21:42Z",
            "content": "Punjab Kings have stepped forward to address the recent mix-up regarding the acquisition of Shashank Singh during the IPL 2024 mini-auction. The franchise found itself in a moment of confusion when t… [+1722 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Barca Blaugranes"
            },
            "author": "Gill Clark",
            "title": "‘Unacceptable!’ - Xavi blasts Barcelona’s lack of..",
            "description": "The boss has made his feelings clear",
            "url": "https://www.barcablaugranes.com/2023/12/21/24010052/unacceptable-xavi-blasts-barcelonas-lack-of-spirit-against-almeria",
            "urlToImage": "https://cdn.vox-cdn.com/thumbor/eJMNTQ-Ls466ts4uCuL1iewYpyQ=/0x0:2690x1408/fit-in/1200x630/cdn.vox-cdn.com/uploads/chorus_asset/file/25177399/1863292864.jpg",
            "publishedAt": "2023-12-20T23:00:00Z",
            "content": "Barcelona coach Xavi Hernandez was understandably not happy with his players after seeing his team struggle to beat Almeria at home in La Liga.We suffered much more than I expected, he told reporters… [+1148 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "ESPN India"
            },
            "author": "Adam Brown",
            "title": "Liverpool ratings: Jones earns 9/10 in big Carabao Cup win",
            "description": "Liverpool put in a strong performance to reach the Carabao Cup semifinals, but it was Curtis Jones who made the case to keep starting for Jurgen Klopp.",
            "url": "https://www.espn.in/football/story/_/id/39156000/liverpool-ratings-curtis-jones-big-carabao-cup-win",
            "urlToImage": "https://a1.espncdn.com/combiner/i?img=%2Fphoto%2F2023%2F1220%2Fr1269024_1296x729_16%2D9.jpg",
            "publishedAt": "2023-12-20T22:45:00Z",
            "content": "Liverpool booked their place to the Carabao Cup semifinals on Wednesday night as they registered a 5-1 victory over West Ham United at Anfield.\r\nA quick start to the game saw the Reds looking to play… [+6011 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Inshorts.com"
            },
            "author": "Anmol Sharma",
            "title": "IPL 2024 likely to begin on..",
            "description": "The trading window for IPL 2024 reopened on Wednesday, a day after the IPL 2024 auction. As per the guidelines",
            "url": "https://inshorts.com/en/news/trading-window-for-ipl-2024-reopens-1703087891550",
            "urlToImage": "https://static.inshorts.com/inshorts/images/v1/variants/jpg/m/2023/12_dec/20_wed/img_1703085967263_519.jpg?",
            "publishedAt": "2023-12-20T22:36:22Z",
            "content": null
        },
        {
            "source": {
                "id": null,
                "name": "Indiansuperleague.com"
            },
            "author": null,
            "title": "Kalinga Super Cup 2024..",
            "description": "Mohun Bagan Super and East Bengal FC are set to lock horns on January 19 in what is going to be the third Kolkata derby of the season.",
            "url": "https://www.indiansuperleague.com/news/kalinga-super-cup-2024-full-fixtures-released-by-the-aiff",
            "urlToImage": "https://www.indiansuperleague.com/static-assets/waf-images/a4/5c/ec/16-9/IXXf5dz6yh.png",
            "publishedAt": "2023-12-20T22:32:54Z",
            "content": "Kalinga Super Cup 2024: Full fixtures released by the AIFF\r\nMohun Bagan Super and East Bengal FC are set to lock horns on January 19 in what is going to be the third Kolkata derby of the season.\r\nThe… [+6028 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Indiansuperleague.com"
            },
            "author": null,
            "title": "The team fought even with nine men: Mohun Bagan SG's ..",
            "description": "Mohun Bagan SG head coach Juan Ferrando shared his views after suffering their first defeat of the season.",
            "url": "https://www.indiansuperleague.com/news/the-team-tried-even-with-nine-men",
            "urlToImage": "https://www.indiansuperleague.com/static-assets/waf-images/fd/4b/22/16-9/UBcPD1gLLw.png",
            "publishedAt": "2023-12-20T19:25:03Z",
            "content": "The team fought even with nine men: Mohun Bagan SG's Juan Ferrando\r\nMohun Bagan SG head coach Juan Ferrando shared his views after suffering their first defeat of the season.\r\nMohun Bagan Super Giant… [+3212 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Crictoday.com"
            },
            "author": "Venkat Narayanan",
            "title": "Shardul Thakur is Chennai Super Kings best buy...",
            "description": "RP Singh has praised the Chennai Super Kings' acquisition of all-rounder Shardul Thakur in the IPL 2024 auction",
            "url": "http://crictoday.com/cricket/news/shardul-thakur-is-chennai-super-kings-best-buy-of-ipl-2024-auction-rp-singh/",
            "urlToImage": "https://crictoday.com/wp-content/uploads/2023/12/ezgif.com-jpg-to-webp-converted-82.webp",
            "publishedAt": "2023-12-20T17:05:11Z",
            "content": "Former Indian pacer RP Singh has lauded the signing of Indian all-rounder Shardul Thakur as the standout acquisition for the five-time champions, Chennai Super Kings, at the IPL 2024 auction. Thakur,… [+1581 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "The Cricket Times"
            },
            "author": "Vishwanath Hariharan",
            "title": "BBL|13, REN vs HEA: Match Prediction..",
            "description": "Melbourne Renegades vs Brisbane Heat Dream11 Team for today's match - December 21, 8:15 am GMT | Big Bash League 2023-24. The clash will take place at the Docklands Stadium in Melbourne.",
            "url": "https://crickettimes.com/2023/12/bbl13-ren-vs-hea-match-prediction-dream11-team-fantasy-tips-pitch-report-melbourne-renegades-vs-brisbane-heat/",
            "urlToImage": "https://crickettimes.com/wp-content/uploads/2023/12/Colin-Munro-and-Nic-Maddinson.webp",
            "publishedAt": "2023-12-20T16:31:04Z",
            "content": "Melbourne Renegades will take on Brisbane Heat in the tenth match of the Big Bash League (BBL) 2023-24. The battle will be hosted by the Docklands Stadium in Melbourne on Thursday (December 21).\r\nThe… [+2175 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Sporting News"
            },
            "author": null,
            "title": "Rodri admits to 'very bad feeling' in Man City..",
            "description": "Get all the latest Soccer news, highlights, scores, schedules, standings and more from Sporting News Canada.",
            "url": "https://www.sportingnews.com/ca/soccer",
            "urlToImage": "https://static.sportingnews.com/1.41.0.3/themes/custom/tsn/logo.jpg",
            "publishedAt": "2023-12-20T14:27:51Z",
            "content": null
        },
        {
            "source": {
                "id": "cnn",
                "name": "CNN"
            },
            "author": "Amy Woodyatt",
            "title": "Eight-year-old chess ‘phenomenon’ wins women’s first prize at ..",
            "description": "An eight-year-old British schoolgirl “phenomenon” won the women’s first prize in the Blitz category at this year’s European Rapid and Blitz Chess Championship in Zagreb, Croatia.",
            "url": "https://www.cnn.com/2023/12/20/sport/bodhana-sivanandan-eight-year-old-european-blitz-chess-intl-spt/index.html",
            "urlToImage": "https://media.cnn.com/api/v1/images/stellar/prod/231220125845-bodhana-sivanandan-220823.jpg?c=16x9&q=w_800,c_fill",
            "publishedAt": "2023-12-20T14:25:00Z",
            "content": "An eight-year-old British schoolgirl phenomenon won the womens first prize in the Blitz category at this years European Rapid and Blitz Chess Championship in Zagreb, Croatia.\r\nThe super talented as t… [+1391 chars]"
        },
        {
            "source": {
                "id": "espn-cric-info",
                "name": "ESPN Cric Info"
            },
            "author": "Firdose Moonda",
            "title": "Dave Houghton resigns as Zimbabwe head coach",
            "description": "He steps down from the role after Zimbabwe failed to qualify for the 2024 T20 World Cup",
            "url": "https://www.espncricinfo.com/story/dave-houghton-resigns-as-zimbabwe-head-coach-1413683",
            "urlToImage": "https://img1.hscicdn.com/image/upload/f_auto/lsci/db/PICTURES/CMS/353900/353981.6.jpg",
            "publishedAt": "2023-12-20T14:15:37Z",
            "content": "Dave Houghton will remain with ZC and will be reassigned to a different role at the organisation  •  Associated Press"
        },
        {
            "source": {
                "id": null,
                "name": "The Indian Express"
            },
            "author": "Express News Service",
            "title": "National Sports Awards 2023: Satwik-Chirag to get Khel Ratna..",
            "description": "The Ministry of Youth Affairs & Sports confirmed the national award recipients for 2023 and doubles duo of Satwik-Chirag will get the highest honour in Major Dhyan Chand Khel Ratna Award.",
            "url": "https://indianexpress.com/article/sports/national-sports-awards-2023-satwik-chirag-shami-9076492/",
            "urlToImage": "https://images.indianexpress.com/2023/12/Sports-Awards-2023.jpg",
            "publishedAt": "2023-12-20T13:53:55Z",
            "content": "India’s star badminton duo of Chirag Shetty and Satwiksairaj Rankireddy were confirmed as the recipients of the Major Dhyan Chand Khel Ratna Award as the sports ministry on Wednesday confirmed the an… [+3904 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "India TV News"
            },
            "author": "Written by Aditya Kukalyekar",
            "title": "Sachin Tendulkar's all-time record broken by ..",
            "description": "New Zealand defeated Bangladesh in the second of the three-match ODI series on Wednesday (December 20) as they chased down 292 runs with ease. Sarkar's knock of 169 runs, however, was lauded by many but how did he break Tendulkar's record? Let's see",
            "url": "https://www.indiatvnews.com/sports/cricket/bangladesh-s-soumya-sarkar-breaks-sachin-tendulkar-s-all-time-record-with-his-169-run-knock-vs-new-zealand-ban-vs-nz-2023-12-20-908192",
            "urlToImage": "https://resize.indiatvnews.com/en/resize/newbucket/1200_-/2023/12/untitled-design-2023-12-20t181116-1703076097.jpg",
            "publishedAt": "2023-12-20T12:42:10Z",
            "content": "Bangladesh batter Soumya Sarkar has come back to national team in the ODI format after quite a long gap. But within a couple of appearances, he has made the comeback a memorable one with a 169-run kn… [+1361 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricxtasy.com"
            },
            "author": "Chandra Moulee Das",
            "title": "CSK CEO responds to trade rumours with Mumbai Indians",
            "description": "Speculation arose following Mumbai Indians' decision to appoint Hardik Pandya as captain after a groundbreaking trade deal, causing unrest within the team",
            "url": "https://cricxtasy.com/indian-premier-league-ipl/csk-ceo-responds-to-trade-rumours-mumbai-indians-ipl-2024/cid13076099.htm",
            "urlToImage": "https://cricxtasy.com/static/c1e/client/95405/uploaded_original/150b4e05ed5247eb3745059364f75dfc.jpeg",
            "publishedAt": "2023-12-20T12:31:42Z",
            "content": "Amid strong speculations doing the rounds, Chennai Super Kings (CSK) has now categorically denied engaging in any trade negotiations with Mumbai Indians (MI) for the upcoming Indian Premier League (I… [+1667 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Hindustan Times"
            },
            "author": "HT Sports Desk",
            "title": "Gill's world No. 1 reign cut short by ..",
            "description": "Babar Azam has replaced Shubman Gill as the No.1-ranked ODI batter in the world. | Cricket",
            "url": "https://www.hindustantimes.com/cricket/shubman-gills-reign-as-world-no-1-cut-short-by-babar-azam-despite-fewer-runs-bishnoi-displaced-in-less-than-2-weeks-101703074323102.html",
            "urlToImage": "https://www.hindustantimes.com/ht-img/img/2023/12/20/1600x900/babar-gill-getty_1703074613447_1703074619993.jpg",
            "publishedAt": "2023-12-20T12:19:26Z",
            "content": "Babar Azam has replaced Shubman Gill as the No. 1 ODI batter in the world with the star Pakistan batter taking back the position in less than 45 days after surrendering it. Gill, who on November 8 di… [+2010 chars]"
        },
        {
            "source": {
                "id": "the-times-of-india",
                "name": "The Times of India"
            },
            "author": "TOI Sports Desk",
            "title": "Watch: MS Dhoni and Rishabh Pant play tennis ..",
            "description": "Cricket News: Following his active participation in the IPL 2024 auction, Delhi Capitals captain Rishabh Pant decided to unwind by trying his hand at tennis in Duba",
            "url": "https://timesofindia.indiatimes.com/sports/cricket/ipl/top-stories/watch-ms-dhoni-and-rishabh-pant-play-tennis-in-dubai-after-ipl-auction/articleshow/106154468.cms",
            "urlToImage": "https://static.toiimg.com/thumb/msid-106154610,width-1070,height-580,imgsize-40680,resizemode-75,overlay-toi_sw,pt-32,y_pad-40/photo.jpg",
            "publishedAt": "2023-12-20T10:48:03Z",
            "content": "IPL Auction: Mitchell Starc, Pat Cummins set salary records"
        },
        {
            "source": {
                "id": null,
                "name": "Prokabaddi.com"
            },
            "author": null,
            "title": "\"We planned to play cautiously and it worked\" - Jaideep Dahiya",
            "description": "Skipper Jaideep Dahiya talks about his team's approach on Tuesday after Haryana Steelers edged out Gujarat Giants 31-29 in a nail-biting encounter. Check out what he had to say in the post-match press conference and keep following all the action on Pro Kabadd…",
            "url": "https://www.prokabaddi.com/videos/we-planned-to-play-cautiously-and-it-worked---jaideep-dahiya-press-conference---december-19-pkl-season-10",
            "urlToImage": "https://www.prokabaddi.com/static-assets/waf-images/7c/36/ae/16-9/LpEaDwwyTd.png",
            "publishedAt": "2023-12-20T09:17:29Z",
            "content": "\"We planned to play cautiously and it worked\" - Jaideep Dahiya | Press Conference - December 19 | PKL Season 10\r\nSkipper Jaideep Dahiya talks about his team's approach on Tuesday after Haryana Steele… [+372 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricket.one"
            },
            "author": "Sanjay Sen",
            "title": "Cricket Fantasy Predictions Today",
            "description": "Premium Aussies (PMU) will face Premium Windees (PMW) in the fifth match of the second edition of American Premier League T20 tournament. Ahead of the game, here is a look at the top player picks, the important player stats, the top captain and vice-captain c…",
            "url": "https://cricket.one/fantasy-analysis/cricket-fantasy-predictions-today-american-premier-league-t20-pmu-vs-pmw-match-5-cricket-exchange-fantasy-teams/6582a98cde38a068f4cd7407",
            "urlToImage": "https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703057962357_Cutting.jpeg",
            "publishedAt": "2023-12-20T08:45:00Z",
            "content": "PMU vs PMW, American Premier League T20: Cricket Fantasy Tips and Teams for the fifth match (Source: x.com)\r\nPremium Aussies (PMU) and Premium Windees (PMW) will square off against each other in the … [+5435 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "YouTube"
            },
            "author": null,
            "title": "Every Angle Of Ja Morant's GAME-WINNER! ",
            "description": "Ja Morant leads the Memphis Grizzlies with 34 points, 6 rebounds and 8 assists, including the game-winning driving floater as time expires to lift Memphis ov...",
            "url": "https://www.youtube.com/watch?v=5zR0Z0snbzk",
            "urlToImage": "https://i.ytimg.com/vi/5zR0Z0snbzk/maxresdefault.jpg",
            "publishedAt": "2023-12-20T04:20:32Z",
            "content": null
        },
        {
            "source": {
                "id": "espn-cric-info",
                "name": "ESPN Cric Info"
            },
            "author": "Firdose Moonda",
            "title": "De Zorzi stirs South Africa up with..",
            "description": "He lifted South Africa with 119* on a tricky track, an accomplishment that bettered their collective effort in each of their last three ODIs against India",
            "url": "https://www.espncricinfo.com/story/tony-de-zorzi-stirs-south-africa-up-with-positive-play-in-bid-to-make-a-spot-his-own-1413602",
            "urlToImage": "https://img1.hscicdn.com/image/upload/f_auto/lsci/db/PICTURES/CMS/372800/372820.6.jpg",
            "publishedAt": "2023-12-20T04:02:45Z",
            "content": "Match AnalysisHe lifted South Africa with 119* on a tricky track, an accomplishment that bettered their collective effort in each of their last three ODIs against India\r\nTony de Zorzi scored his maid… [+5473 chars]"
        },
        {
            "source": {
                "id": null,
                "name": "Cricbuzz"
            },
            "author": null,
            "title": "The dynamics behind the ground-breaking 24.75 cr bid",
            "description": "KKR managed to secure the services of Mitchell Starc and staved off Gujarat Titans due to some smart choices",
            "url": "https://www.cricbuzz.com/cricket-news/128878/the-dynamics-behind-the-ground-breaking-2475-cr-bid-cricbuzzcom",
            "urlToImage": "https://static.cricbuzz.com/a/img/v1/600x400/i1/c366671/kkr-went-home-happier-after-their-moves-worked-well-in-the-auction.jpg",
            "publishedAt": "2023-12-20T01:50:41Z",
            "content": "After the day's proceedings, Sanjiv Goenka and Kumar Sangakkara engaged in sagacious and engrossing deliberations over Indian Premier League (IPL) salaries breaching the glass ceiling. \"Mini auctions… [+4277 chars]"
        }
    

    ]
}