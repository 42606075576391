import React from "react";
import Search from '../components/Search';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./BasicChat.css"
import { TypeAnimation } from "react-type-animation";
import { modes } from "../mock/PromptAssistanceExamples";
import { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

function GenKnowledge() {
    const [{}, dispatch] = useStateValue();


    return (
        <div>
            <div className="container">
                <Header />
                <div className="container_body">
                    
                    <Search home={true} showButtons={true} mode={"landing"} placeholder={""} route={true} style={{ fontWeight: 400 }} />
                </div>
            </div>
            <Footer />
        </div>



    );
}

export default GenKnowledge;