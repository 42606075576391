import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CardActionArea, CardActions } from '@mui/material';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

export default function MultiActionAreaCard({name, image, description, url, onClick, navigate}) {
  return (
    <>
    <Card sx={{ boxShadow:"2px 2px 2px 2px rgba(0,0,0,0), 2px 2px 2px 2px rgba(0,3,1,0.14), 2px 2px 2px 2px rgba(0,0,0,0.12)" }} onClick={(_)=>navigate("/snap")}>
      <CardActionArea>
       {image != "" && <CardMedia
          component="img"
          image={image}
          alt="green iguana"
        /> }
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: "'bitter', serif" }}>
            {name}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            {description}
          </Typography> */}
        </CardContent>
      </CardActionArea>
      {/* <CardActions style={{ justifyContent: "space-between" }}>
        
        <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }} onClick={(_)=>onClick()}>
        <SearchIcon style={{fontSize:"20px", opacity:1, marginTop:"1px"}}/> Discuss with AI 
        </Button>
        <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }} onClick={(_)=>window.location.assign(`${url}`)}>
          Read Article
        </Button>
      </CardActions> */}

      <CardActions style={{ justifyContent: "space-between" }}>

            <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }} onClick={(_)=>window.location.assign(`${url}`)}>
            <StickyNote2Icon style={{fontSize:"20px", opacity:1, marginTop:"1px", padding:"5px"}}/> AI Summary 
            </Button>

            <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }} onClick={(_)=>onClick()}>
            <SearchIcon style={{fontSize:"20px", opacity:1, marginTop:"1px", padding:"3px"}}/> Discuss with AI 
            </Button>
            {/* <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }} onClick={(_)=>window.location.assign("url")}>
              Read Article
            </Button> */}
      </CardActions>

    </Card>
    <br/>
    </>
  );
}
