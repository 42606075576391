import { useEffect } from 'react';
import axios from 'axios';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import { getCodeFromUrlParams, getUserFromBrowser, getUserFromAuthServiceSession, getSubscriptionFromBrowser, getUserFromGoogleGivenCode } from '../helpers/AuthWrapperHelper';
import { useLocation } from 'react-router-dom';
import { fetchActiveSubscriptions, listenToUpdatesInSubscriptionsCollection } from '../components/firebaseHelpers/listenToUpdatesInSubscriptionsCollection';

export const useAuthWrapper = () => {
  const [{ user, subscription }, dispatch] = useStateValue();
  const location = useLocation();

  useEffect(() => {
    
   // axios.defaults.withCredentials = true;

    const setAppStateOnPageMount = async () => {
      
      //if a user or active subcription exists in state return
      // if (!!user || !!subscription) {  
      //   return;
      // }

      if(!!user){ //if user exists return
        return;
      }

      const code = getCodeFromUrlParams(location); //check if there is code in the urlparams 

      if (!!code) {  //there is some info in the url params => user has re-authenticated
        const userFromGoogle = await getUserFromGoogleGivenCode(code); //fetch user from firestore using newly generated code
        //const userSubscription = await fetchActiveSubscriptions(userFromGoogle.sub); //fetch for any subscriptions
        
        localStorage.setItem("user", JSON.stringify(userFromGoogle));
        dispatch({type: actionTypes.SET_USER, user: userFromGoogle});
        //start listening to subscription events
        //listenToUpdatesInSubscriptionsCollection(userFromGoogle.sub, dispatch);
        
        // if(!!userSubscription){
        //   localStorage.setItem("subscription", JSON.stringify(userSubscription));
        //   dispatch({type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscription: userSubscription});
        // }
        return;
      }

      //if a user does not exist in state, check if user data lives in browser
      const userFromBrowser = getUserFromBrowser();
      //if user lives in browser use that to log them in app
      if(!!userFromBrowser){
        dispatch({ type: actionTypes.SET_USER, user: userFromBrowser });
        //it is already in local browser storage

        //start listening for changes in subscription collection associated with this user
      //  listenToUpdatesInSubscriptionsCollection(userFromBrowser, dispatch); //
      }

      //if there is no subscription in state, check if subscription data lives in browser
      //const subscriptionFromBrowser = getSubscriptionFromBrowser();
      //if subscription data lives in browser store this information in app state
      // if(!!subscriptionFromBrowser){
      //   dispatch({type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscriptionFromBrowser});
      // }      
    }
    setAppStateOnPageMount();
  }, []);

  return null;
};

// async function onStart(user, subscription,  dispatch) {




//   const subscription = await fetchActiveSubscriptions(user.sub);
//   if (!!subscription) {
//     localStorage.setItem("subscription", JSON.stringify(subscription));
//     dispatch({ type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscription: subscription });
//   }
//   listenToUpdatesInSubscriptionsCollection(user.sub, dispatch);
//   dispatch({ type: actionTypes.SET_USER, user: user });
//   dispatch({type: actionTypes.SET_LOGIN_LOADING, loginLoading: false});  
// }


// import React, { useEffect } from 'react';
// import axios from 'axios';
// import { useStateValue } from '../statemanagement/StateProvider';
// import { actionTypes } from '../statemanagement/reducer';
// import { getCodeFromUrlParams, getUserFromAuthServiceSession, getUserFromGoogleGivenCode } from '../helpers/AuthWrapperHelper';
// import { useLocation } from 'react-router-dom';
// import { fetchActiveSubscriptions, listenToUpdatesInSubscriptionsCollection } from '../components/firebaseHelpers/listenToUpdatesInSubscriptionsCollection';

// //wraps the entire app
// //can be used for setup on load
// export const AuthWrapper = () => {

//   axios.defaults.withCredentials = true;
//   const [{ user }, dispatch] = useStateValue();
//   const location = useLocation();

//   useEffect(() => {
    
//     const attemptToLoginUserOnMount = async () => {
//       if (!!user) {
//         console.log("User has already logged in");
//         return;
//       }
//       //user does not exist in local state
//       const user = await getUserFromAuthServiceSession();//nothing has to be passed in since user is held in backend session store
//       if(!!user){
//         await onStart(user, dispatch);
//         return;
//       }
      

//       const code = getCodeFromUrlParams(location);
//       if (!!code) {
//         const user = await getUserFromGoogleGivenCode(code);
//         if(!!user){
//           await onStart(user, dispatch);
//           return;
//         }
        
//       }
//     }
//     console.log(`User: ${user}`);
//     attemptToLoginUserOnMount(user);
//   }, []);

//   return null;
// }

// async function onStart(user, dispatch) {
//   const subscription = await fetchActiveSubscriptions(user.sub);//fetch the subscription associated with the user sub
//   if (!!subscription) {
//     dispatch({ type: actionTypes.SET_PAYPAL_SUBSCRIPTION, subscription: subscription });
//   }
//   listenToUpdatesInSubscriptionsCollection(user.sub, dispatch);//listen for subscription updates, update app state 
//   dispatch({ type: actionTypes.SET_USER, user: user });//update user to local state 
// }
