import React from "react";
import "./Short.css";
import { Card, CardActionArea, CardMedia, CardContent, Typography, CardActions, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AppFooter from "./AppFooter";
import {useNavigate} from "react-router-dom"
import { clientCache } from "../mock/ClientCache";
import { actionTypes } from "../statemanagement/reducer";
import { useStateValue } from "../statemanagement/StateProvider";

const Short = ({ imageUrl, name, description }) => {

    const navigate = useNavigate();
    const [_, dispatch] = useStateValue();

    return (
        <div className="short">
            <Card
                raised
                sx={{ height: "100vh" }}
            >
                <CardContent sx={{height:"70vh"}}>
                    <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: "'bitter', serif" }}>
                        {name}
                    </Typography>
                    <CardMedia
                        component="img"

                        image={imageUrl}
                        alt={"alt"}
                        sx={{ height: "40%", padding:"10px"  }}
                    />
                    <Typography variant="body2" color="text.secondary" sx={{padding:"10px"}}>
                        {"The Indian Premier League teams don't merely engage in extravagant spending when it comes to acquiring new talent at auctions; rather, their approach is characterized by a meticulous and extensive process that spans over several months. This rigorous journey involves thorough and exhaustive scouting efforts that traverse the entire expanse of the nation, leaving no stone unturned in the pursuit of discovering the next cricketing sensation. It's a comprehensive endeavor that entails delving deep into the talent pool, scouring not only the well-known hubs but also exploring the hidden gems hidden in the remotest corners of the country. "}
                    </Typography>
                    
                </CardContent>
                <CardActions style={{ justifyContent: "space-between", height:"30vh" }}>
        
                        <Button onClick={(_)=>{
                            setTimeout(()=>{
                                dispatch({
                                    type: actionTypes.UPDATE_CONVERSATION_HISTORY,
                                    enrichedConversationHistory: clientCache(`David Warner's Impressive Comeback: Australian Cricket Legend Urges Him Not to Retire Yet`) 
                                  });
                            }, 50);

                            navigate("/query");
                        }} size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }}>
                        <SearchIcon style={{fontSize:"20px", opacity:1}}/> Discuss with AI 
                        </Button>
                        <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }}>
                        Read Article
                        </Button>
                    </CardActions>
                
            </Card>
            <AppFooter/>
        </div>
    );
};

export default Short;
//https://onecricketnews.akamaized.net/parth-editor/oc-dashboard/news-images-prod/1703158664218_GByxluKXwAAwslW.jpeg





/**
 * <Card sx={{ height: "100%" }}>
        <CardActionArea>

          <CardMedia
            sx={{ width: desiredWidth, height: desiredHeight }}
            component={"img"}
            image="https://i.ytimg.com/vi/8IOmIAk-c7o/maxresdefault.jpg"
          />
        </CardActionArea>
      </Card>
 */