import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HttpsIcon from '@mui/icons-material/Https';
import axios from "axios";
import { StripeCheckoutComponent } from '../components/StripeCheckout/StripeCheckoutComponent';

export default function BasicCard({login, user, subscription}) {

    const bull = (
        <Box
          component="span"
          sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
          •
        </Box>
      );
  return (
    <Card sx={{ minWidth: 275, background:"whitesmoke", border:"none", boxShadow:"none" }}>
       
      <CardContent>
      <CardActions>
        {!user && <Button className="get_started" style={{margin:"auto"}} onClick={(_) => login()}>
            Sign in with Google to Continue <HttpsIcon style={{ paddingLeft: "9px", fontSize: "x-large", paddingBottom: "5px", paddingRight: "0px", paddingTop: "3px" }} />
        </Button>}
        {/* {!!user && !subscription && <PayPalComponent style={{paddingTop:"5%", paddingBottom:"5%"}} />} */}
        
      </CardActions> <br/>
        <Typography sx={{ fontSize: 30 }} color="text.primary" gutterBottom>
          {/* ChatDragon.ai Pro (₹49) */}
        </Typography>
         {/* <br /> */}
        {/* <Typography variant="h5" component="div">
         ChatDragon.ai Pro 
        </Typography> 
       */}
        
        {/* {!!user && !subscription && <StripeCheckoutComponent user={user} />}  */}

        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Developed by ex-Microsoft Copilot creators
        </Typography> */}
        <br />
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Developed by ex-Microsoft AI creators
        </Typography>
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} More than 40x cheaper than ChatGPT Plus!
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Cancel Anytime
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} 40 times cheaper than Jasper!
        </Typography> */}
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} GPT-4 access (Worlds most powerful AI)
        </Typography>
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Worlds most powerful AI 
        </Typography> */}
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Grounded on Bing Search results
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Citations/References  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Complete AI control with Refinements 
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Make it Shorter, Simpler, Detailed etc.  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Add emojis, Increase Font size, Retry etc.  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI Generated followup questions  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Copy or Share results across all Apps
        </Typography>  
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Custom AI for Writing and Refinements
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI Video tutorials 
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) File Upload  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) AI Powered Word Processor  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) End to End encryption  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) New Monthly AI tutorials
        </Typography>     */}
      </CardContent>
      
      {/* <CardActions>
      <Button size="small" variant='outline'>How do we provide all this for less than a dollar?</Button>
      </CardActions> */}
    </Card>
  );
}
