import React, { useEffect } from 'react'
import Header from './Header';
import Footer from './Footer';
import "./BasicChat.css"
import GenericSearch from './GenericSearch';
import NearMeIcon from '@mui/icons-material/NearMe';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import IconRow from './IconRow';

const LandingPage = () => {

    const [{_}, dispatch] = useStateValue();

    useEffect(()=>{
        dispatch({
            type: actionTypes.SET_APP_MODE,
            appMode: "generic"
        })
    },[])

    return (
        <div>
            <div className="container">
                <Header />
                <GenericSearch showSecondaryText={true} override={true} typeAnimationList={[
                                "Bharat's Answer Engine",
                            ]} animationFont={"1.7rem"} />

                {/* <hr/> */}
                <IconRow/>
                <br />
                <br />
                {/* <hr/>
                <GenericSearch mode="location" typeAnimationList={[
                                "Location Services, Ask AI e.g. Police Station address" 
                            ]} animationFont={"1.5rem"} icon={<LocationOnIcon style={{width:"100px", height:"80px"}}/>} />
                
                <hr/> */}
                <h1>Add Infinite scroll here</h1>  
                {/* <GenericSearch mode="medical" icon={<LocalHospitalIcon style={{width:"100px", height:"80px", color:"red"}} />} typeAnimationList={[
                                "Ask AI General Health & Lifestyle questions",
                            ]} animationFont={"1.5rem"} secondaryText={"We only use World Health Organization (WHO) as our knowledge source"} disclaimer={"*Any Medical or Health related information provided on BharatChat.ai is for general informational purposes only and should not be considered as medical advice. All content and information on this website are not intended to diagnose, treat, cure, or prevent any disease. Always seek the advice of a qualified healthcare professional with any questions or concerns you may have regarding a medical condition."} /> */}
                
                
            </div>

                          
            
            <Footer />
        </div>
    )
}

export default LandingPage