import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const location = useNavigate();
    return (
        <div className="footer" style={{ background: "black", opacity: "0.9", position: "fixed", bottom: "0", width: "100%" }}>
            <div className="footer__inner" style={{ padding: "1.5rem", color: "white", display: "flex", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>

                {/* <div style={{margin:"0 1.8%"}}>Privacy Policy</div> */}
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="mailto: support@chatdragon.ai">Contact Us</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="mailto: support@chatdragon.ai?subject=Re:%20Careers&body=Please%20attach%20your%20CV">Careers</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/privacy")}>Privacy</a></div>
                {/* <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/about")} >About Us</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/refund")} >Refund Policy</a></div> */}
            </div>

        </div>
    );
}

export default Footer;