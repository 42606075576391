import './App.css';
import Results from './pages/Results';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BasicChat from './pages/BasicChat';
import PrivacyPolicy from "./pages/Privacy";
import Docs from "./pages/Docs";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Login } from './pages/Login';
import Success from './pages/Success';
import Cancelled from './pages/Cancelled';
import { useEffect } from 'react';
import LandingPage from './pages/LandingPage';
import GenKnowledge from './pages/GenKnowledge';
import Read from './pages/Read';
import Snap from './pages/Snap';
import SearchExperience from './pages/SearchExperience';


function App() {

  //const stripePromise = loadStripe("pk_test_51NyFHgSJ5CXvl4Nx2QpKubTL1RtyFgnpywfx62Ky6oHUyu0RNvtf34YzxhWjPKtqWK0p45rFWmkFWKP4s5Lch0KG00PU2GW0mY");


  // const clientId = '682480431825-8k486argc3op967q4pgrfve7v9ippu07.apps.googleusercontent.com';

  // useEffect(()=>{
  //   console.log(`Google Client id from environment variable is: ${JSON.stringify(process.env)}`);
  // },[])

  return (
      <div className="app">
      {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>  */}
        {/*  <Elements stripe={stripePromise}> */}
              <Router>
                    <Routes>
                      <Route exact path="/login" element={<Login />} />
                      <Route exact path="/query" element={<Results />} />
                      <Route exact path="/" element={<BasicChat />} />
                      {/* <Route exact path="/gk" element={<GenKnowledge />} /> */}
                      <Route exact path="/backup" element={<LandingPage/>} />
                      {/* <Route exact path="/docs" element={<Docs />} /> */}
                      <Route exact path="/privacy" element={<PrivacyPolicy />} />
                      <Route exact path="/snap" element={<Snap />} />
                      <Route exact path="/searchexperience" element={<SearchExperience />} />
                      {/* <Route path="/read" element={<Read />} /> */}
                      {/* <Route path="/success" element={<Success/>} />
                      <Route path="/cancelled" element={<Cancelled />} /> */}
                    </Routes>
              </Router>
          {/* </Elements> */}
        {/* </GoogleOAuthProvider> */}
       
      </div>
   
      
   
    
  );
}

export default App;

