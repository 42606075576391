//backend orchestrator - main backend for our app 
//services provided: auth and LLM apis
// export const orchestratorBase = "https://basic1964.azurewebsites.net"; // https://authservice1964.azurewebsites.net

export const orchestratorBase = "https://authservice1964.azurewebsites.net"; // ** TODO: update to India hoster server
//Text-generation apis - program/service that makes calls to openai/LLM
export const textGenerationBase = orchestratorBase;
export const textGenerationMultiturn = `${orchestratorBase}/multi`;
export const textGenerationBaseFollowup = `${orchestratorBase}/question`;

export const gptUrl = `${orchestratorBase}/gpt`

export const gptServiceUrl = `${orchestratorBase}/gpt-service`; 
export const gptServiceUrlGeneric = `${orchestratorBase}/gpt-service-generic`;
export const gptServiceUrlMedical = `${orchestratorBase}/gpt-service-medical`;
export const gptServiceUrlLocation = `${orchestratorBase}/gpt-service-location`;

export const gptServiceMedicalUrl = `${orchestratorBase}/gpt-medical-service`;

//frontend - the production url that hosts in browser the SPA
//const frontend = "https://basic-chat-75e5e--paypal-auth-verify-y2lvilsr.web.app";
const frontend = "https://basic-chat-75e5e--paypal-auth-verify-3mg97154.web.app";

//Authentication - direct provider - the requirement by auth provider to route to once user has been authenticated via provider credentials
export const redirectUri = `${frontend}/login`;

//Authentication - backend - authentication related services coupled with main backend
export const endAuthSession = `${orchestratorBase}/endSession`; //clearing session store associated with main backend 
export const sessionUser = `${orchestratorBase}/user`; //reading user from session store to be sent to client
export const fetchUser = `${orchestratorBase}/getAuthenticatedUser`; //routine that takes in a code and returns a user to the client

//Paypal
export const paymentBase = "https://paypalservice.azurewebsites.net"; //manages paypal api interactions for create and cancel
export const cancelPaypalSubscriptionUrl = `${paymentBase}/api/paypal/cancelSubscription`; //responsible for cancelling subscriptions
export const createPaypalSubscription = `${paymentBase}/api/paypal/create-subscription`; //responsible for creating a new paypal subscription
export const subscriptionDetailUrl = `${paymentBase}/api/paypal/getSubscriptionDetail`;

//Stripe
export const createStripeCheckoutSession = `${paymentBase}/create-checkout-session`;