import React from "react";
import "./Snap.css";
import Short from "./Short";
import { useStateValue } from "../statemanagement/StateProvider";
import useHeadlines from "../customhooks/useHeadlines";
import MultilingualShort from "./MultilingualShort";

const Snap = () => {

    const [{news}, _] = useStateValue();
    useHeadlines();


    const elements = news.map(u=>{
        return <Short imageUrl={u.image.contentUrl} name={u.name} description={u.description} />
    })

    return (
        
        <div className="app__shorts">
            { [<MultilingualShort/>].concat(elements)}
        </div>
        

    );
}

export default Snap;