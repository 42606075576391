import React from 'react';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import ShowChartIcon from '@mui/icons-material/ShowChart';

const IconRow = () => {

  const location = useNavigate();
  const [{_}, dispatch] = useStateValue();
  

  const iconStyle = {
    width: '60px',
    height: '60px',
    marginRight: '10px', // Adjust the value to control the spacing
  };

  const redIconStyle = {
    ...iconStyle,
    color: 'red', // Set the color to red
  };

  const greenIconStyle = {
    ...iconStyle,
    color: 'green', // Set the color to red
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{margin:"auto"}}>
      <LocalHospitalIcon onClick={(_)=>{
        dispatch({ type: actionTypes.SET_APP_MODE, appMode:"medical"});
        location("/basic");
      }} style={redIconStyle} />
        <ShowChartIcon onClick={(_)=>{
        dispatch({ type: actionTypes.SET_APP_MODE, appMode:"finance"});
        location("/basic");
      }} style={iconStyle} />
      <SportsCricketIcon onClick={(_)=>{
        dispatch({ type: actionTypes.SET_APP_MODE, appMode:"sports"});
        location("/basic");
      }} style={greenIconStyle} />
      <LocalMoviesIcon onClick={(_)=>{
        dispatch({ type: actionTypes.SET_APP_MODE, appMode:"entertainment"});
        location("/basic");
      }} style={iconStyle} />
    
      <LocationOnIcon onClick={(_)=>{
        dispatch({ type: actionTypes.SET_APP_MODE, appMode:"location"});
        location("/basic");
      }} style={iconStyle} />
      </div>
    </div>
  );
}

export default IconRow;
