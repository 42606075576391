import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import SearchIcon from '@mui/icons-material/Search';

const AppFooter = () => {
    const location = useNavigate();
    return (
        <div className="footer" style={{ background: "white", display:"flex", position: "fixed", bottom: "0", width: "100%" }}>
            <div className="footer__inner" style={{ margin:"auto", display:"flex", padding: "1rem", color: "white", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>

                {/* <div style={{margin:"0 1.8%"}}>Privacy Policy</div> */}
                {/* <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="mailto: support@chatdragon.ai">Contact Us</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="mailto: support@chatdragon.ai?subject=Re:%20Careers&body=Please%20attach%20your%20CV">Careers</a></div> */}
                  <div style={{ paddingRight:"20px" }}><a style={{ textDecoration: "none", color: "black" }} onClick={(_)=>location("/")}><HomeIcon /></a></div>
                <div style={{ paddingRight:"20px" }}><a style={{ textDecoration: "none", color: "black" }} onClick={(_)=>location("/snap")}><LocalMoviesIcon/></a></div>
                <div style={{ paddingRight:"20px" }}><a style={{ textDecoration: "none", color: "black" }} onClick={(_)=>location("/searchexperience")}><SearchIcon/></a></div>
            
                {/* <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/about")} >About Us</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/refund")} >Refund Policy</a></div> */}
            </div>

        </div>
    );
}

export default AppFooter;