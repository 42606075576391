import { useLocation } from "react-router-dom"; //object which represents current url in the browser
import axios from "axios";
import { fetchUserFromCode, getSessionUser } from "../components/constants/helpers";


//axios.defaults.withCredentials = true;

export const instance = axios.create({
  withCredentials: false, // Allow sending cookies with cross-origin requests
});

// instance.interceptors.request.use((config) => {
//   // Add the "SameSite=None" attribute to cookies
//   const cookieHeader = config.headers['Cookie'];
//   if (cookieHeader) {
//     config.headers['Cookie'] = `${cookieHeader}; SameSite=None`;
//   }

//   return config;
// });

//get code from url params
export const getCodeFromUrlParams = (location) => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    return code;
}

//get user from code using googleapis and backend as intermediary that holds secret 
export const getUserFromGoogleGivenCode = async (code) => {
    try{
        const getGoogleUserFromCode = fetchUserFromCode(); //backend url which communicated with googleapis
        const response = await instance.post(getGoogleUserFromCode, {code: code});
        return response.data; //the user object with sub and email
    } catch(error){
        console.error(`Could not get user from googleapis given code`);
        return null;
    }   
}

export const getUserFromBrowser = () => {
   // console.log("Attempting to get user from browser");
    const user = localStorage.getItem("user");
    //console.log(`User retrieved from browser: ${user}`);
    return JSON.parse(user);
}

export const getSubscriptionFromBrowser = () => {
    console.log("Attempting to get subscription from browser");
    const subscription = localStorage.getItem("subscription");
    console.log(`Subscription retrieved from browser: ${subscription}`);
    return JSON.parse(subscription);
}

export const getUserFromAuthServiceSession = async () => {
    try{
        const respose = await instance.get(getSessionUser());
        return respose.data;
    } catch(error){
        console.error(`Could not get user from auth service session`);
        return null;
    }
}

// export const setPaypalAccessTokenInServer = async () => {
//     try{
//         await axios.get("http://localhost:8890/api/paypal/generate-access-token", {withCredentials: true})
//     } catch (error){
//         console.error(`Paypal Access Token not set in Server session`);
//     }
// }