import React, { useEffect, useState, useRef } from 'react'
import "./Search.css";
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from "react-router-dom"
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ClearIcon from '@mui/icons-material/Clear';
import {submitCustomEvent} from "../helper/customevents";

function InAppSearch({home = false,mode="view", showButtons = false, route=false, placeholder="Ask AI a Question..", showClear = false, w="93%"}) {
  
  const [{term, loading, streaming, user, appMode, news}, dispatch] = useStateValue();
  const [input, setInput] = useState(term);  
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  let placeholderList = [
    "Ask AI anything.."
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % placeholderList.length);
    }, 7000); 

    return () => clearInterval(intervalId);
  }, [placeholderList.length]);

  const defocus_dismisskeyboard = () => {
    if(inputRef.current){
      inputRef.current.blur();
    }
  }

  const search = (e, edit=false) => {
    e.preventDefault();  

    defocus_dismisskeyboard();
    
    if(input === "" || loading || streaming) 
      return;

    postFunc(edit);
    dispatch({type:actionTypes.SET_SEARCH_TERM, term: input });
    dispatch({type:actionTypes.SET_LOADING, loading: true });
    submitCustomEvent('User_Prompt', input);
    setInput("");
  }

  const postFunc = (edit) => {
    if(route){
      navigate("/query")      
  } }

  const getHomeStyle = ()=> {
    let style =  {marginBottom:"5%", width:w };
    return home ? {...style, height:"unset", borderRadius: "18px", boxShadow:"2px 4px 12px rgba(0,0,0,.5)"}: {...style, width: "78vw"};
  }

  return (
    <div>
      {home && <br/>}
      <form className='search' onSubmit={search}>   
          <div className= { "search__input"} style={getHomeStyle()}>
              <SearchIcon style={{opacity:0.65, alignSelf: home  && "flex-start", paddingTop: home && "0.1rem"}} className='search__icon' />
              <textarea rows={7} ref={inputRef} value={input} onChange={e=> setInput(e.target.value)} placeholder={placeholderList[currentIndex]}/>              
              <SendIcon type="submit" onClick={search} style={ home && {"color":"black", alignSelf:"flex-end", fontSize:"1.9rem", marginRight:"5px", opacity:"0.9"}}/>
          </div>
      </form>
    </div>
  )
}

export default InAppSearch