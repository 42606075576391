import { cancelPaypalPayment, cancelPaypalSubscriptionUrl, createPaypalSubscription, createStripeCheckoutSession, endAuthSession, fetchUser, gptServiceMedicalUrl, gptServiceUrl, gptServiceUrlGeneric, gptServiceUrlLocation, gptServiceUrlMedical, gptUrl, redirectUri, sessionUser, subscriptionDetailUrl, textGenerationBase, textGenerationBaseFollowup, textGenerationMultiturn } from "./urls.prod"
import { cancelPaypalPaymentUAT, cancelPaypalSubscriptionUrlUAT, createPaypalSubscriptionUAT, createStripeCheckoutSessionUAT, endAuthSessionUAT, fetchUserUAT, getSubscriptionDetailUrlUAT, gptServiceMedicalUrlUAT, gptServiceUrlGenericUAT, gptServiceUrlLocationUAT, gptServiceUrlMedicalUAT, gptServiceUrlUAT, gptUrlUAT, redirectUriUAT, sessionUserUAT, subscriptionDetailUrlUAT, textGenerationBaseFollowupUAT, textGenerationBaseUAT, textGenerationMultiturnUAT } from "./urls.uat"

//text-generation
export const getTextGenerationBaseApi = () => window.location.href.includes("localhost") ? textGenerationBaseUAT: textGenerationBase;
export const getTextGenerationMultiturnApi = () => window.location.href.includes("localhost") ? textGenerationMultiturnUAT: textGenerationMultiturn;
export const getTextGenerationFollowupApi = () => window.location.href.includes("localhost") ? textGenerationBaseFollowupUAT: textGenerationBaseFollowup;

//gpt service
export const getGPTServiceUrl = (appMode) => {
    switch (appMode) {
      case "generic":
        return window.location.href.includes("localhost") ? gptServiceUrlGenericUAT : gptServiceUrlGeneric;
      case "location":
        // Handle "location" mode logic here
        return window.location.href.includes("localhost") ? gptServiceUrlLocationUAT : gptServiceUrlLocation;
        break;
      case "medical":
        // Handle "medical" mode logic here
        return window.location.href.includes("localhost") ? gptServiceUrlMedicalUAT : gptServiceUrlMedical;
        break;
      default:
        // Handle unknown mode or provide a default case logic here
        return window.location.href.includes("localhost") ? gptServiceUrlGenericUAT : gptServiceUrlGeneric;
        break;
    }
  };

export const getGPTUrl = () => window.location.href.includes("localhost") ? gptUrlUAT: gptUrl;
  

export const getGPTMedicalServiceUrl = () => window.location.href.includes("localhost") ? gptServiceMedicalUrlUAT: gptServiceMedicalUrl;

//authentication
export const getRedirectUri = () => window.location.href.includes("localhost") ? redirectUriUAT : redirectUri;
export const getEndAuthSessionApi = () => window.location.href.includes("localhost") ? endAuthSessionUAT: endAuthSession;
export const getSessionUser = () => window.location.href.includes("localhost") ? sessionUserUAT : sessionUser;
export const fetchUserFromCode = () => window.location.href.includes("localhost") ? fetchUserUAT: fetchUser;

export const getExistingBackendBaseUrl = () => window.location.href.includes("localhost") ? "http://localhost:8080" : fetchUser

//payments
export const getCancelPaypalSubscriptionUrl = () => window.location.href.includes("localhost") ? cancelPaypalSubscriptionUrlUAT: cancelPaypalSubscriptionUrl;
export const getCreatePaypalSubscriptionUrl = () => window.location.href.includes("localhost") ? createPaypalSubscriptionUAT : createPaypalSubscription;
export const getSubscriptionDetailUrl = () => window.location.href.includes("localhost") ? subscriptionDetailUrlUAT: subscriptionDetailUrl;

//stripe intergration
export const getStripeCheckoutUrl = () => window.location.href.includes("localhost") ? createStripeCheckoutSessionUAT: createStripeCheckoutSession;